<template>
          <el-select
            v-model="select_project"
            @change="ProjectChangeChild"
            placeholder="项目"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputProject"
                @keyup.enter.native="enterProject"
                clearable
              ></el-input>
              <!-- <button @click="selectAllproject" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button> -->
              <button style="height: 24px; width: 70px; margin-left: 20px" @click="clearAllproject">清空</button>
              <el-option v-for="item in project_list" :key="item" :label="item" :value="item"></el-option>
              <el-option v-if="project_list == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <!-- <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 150px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in select_project" :key="item">
                <el-tag closable @close="handleCloseProject(item)">{{ item }}</el-tag>
              </div>
            </div> -->
          </el-select>
</template>

<script>
export default {
    watch:{
        inputProject() {
            var _project_list = [];
            this.project_list.filter((item) => {
                if (item.toUpperCase().includes(this.inputProject.toUpperCase())) {
                _project_list.push(item);
                }
            });
            this.project_list = _project_list;
            if (this.inputProject == '') {
                this.$parent.getProject();
            }
        },
    },
  methods: {
    ProjectChangeChild(){
          this.$emit('ProjectChange',this.select_project);
    },
    enterProject() {
      if (this.select_project.includes(this.project_list[0])) {
        console.log('已选中');
      } else this.select_project.push(this.project_list[0]);
      // this.ProjectChangeChild();
    },
    selectAllproject() {
      this.select_project = this.project_list;
      this.ProjectChangeChild();
    },
    clearAllproject(){
      this.select_project = [];
      this.checked_project = false;
      this.ProjectChangeChild();
    },

    handleCloseProject(tag) {
      this.select_project.splice(this.select_project.indexOf(tag), 1);
      this.ProjectChangeChild();
    },
  },
 data() {
    return {
      inputProject:'',
      project_list: [],
      checked_project: false,
      select_project:[]
      
    };
  },
}
</script>
