<template>
  <div>
    <div v-if="user_group.indexOf('测试组') == -1 && user_group.indexOf('特殊权限组') == -1">
      <cw403></cw403>
    </div>
    <div v-if="user_group.indexOf('测试组') > -1 || user_group.indexOf('特殊权限组') > -1">
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            成员的消耗统计模块
            <el-button type="text" style="font-size: 16px" @click="dialogVisible = true">(页面说明)</el-button>
            <font style="margin-left: 20px; color: #409eff">展示顺序:&nbsp;&nbsp;&nbsp;{{ checkList }}</font>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container">
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">分组维度</span>
          <el-checkbox-group @change="demo" v-model="checkList" style="display: inline">
            <el-checkbox label="项目" disabled></el-checkbox>
            <el-checkbox label="渠道"></el-checkbox>
            <el-checkbox label="国家"></el-checkbox>
            <el-checkbox label="人员"></el-checkbox>
            <el-checkbox label="媒体渠道"></el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="options-bar" style="margin-bottom: 20px">
          <span style="color:gray;margin-right:30px;">筛选维度</span>
          <date-picker
            style="margin-right: 10px"
            v-model="date"
          ></date-picker>
          <el-select
            v-model="QQ.man"
            @change="selectRY"
            multiple
            placeholder="人员"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputRY"
                @keyup.enter.native="enterRY"
                clearable
              ></el-input>
              <button @click="selectAll0" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 20px" @click="qinchu0">清空</button>
              <el-option v-for="item in listn" :key="item" :label="item" :value="item"> </el-option>
              <el-option v-if="listn == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 150px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in QQ.man" :key="item">
                <el-tag closable @close="handleCloseman(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
          <el-select
            v-model="QQ.gameName"
            @change="selectXM"
            multiple
            placeholder="项目"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputXM"
                @keyup.enter.native="enterXM"
                clearable
              ></el-input>
              <button @click="selectAll1" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 20px" @click="qinchu1">清空</button>
              <el-option v-for="item in list1" :key="item" :label="item" :value="item"></el-option>
              <el-option v-if="list1 == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 150px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in QQ.gameName" :key="item">
                <el-tag closable @close="handleClose(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
          <el-select
            v-model="QQ.phone"
            @change="selectPT"
            multiple
            placeholder="平台"
            style="margin-right: 10px; width: 180px"
          >
            <el-option v-for="item in list2" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </div>
        <div class="options-bar" style="margin-left: 95px">
          <el-select
            v-model="QQ.country"
            @change="selectGZ"
            multiple
            placeholder="所有国家/地区"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputGZ"
                @keyup.enter.native="enterGZ"
                clearable
              ></el-input>
              <button @click="selectAll3" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 20px" @click="qinchu3">清空</button>
              <el-option v-for="item in list3" :key="item" :label="item" :value="item"> </el-option>
              <el-option v-if="list3 == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 160px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in QQ.country" :key="item">
                <el-tag closable @close="handleCloseGZ(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
          <el-select
            v-model="QQ.mt"
            multiple
            placeholder="所有媒体渠道"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputMT"
                @keyup.enter.native="enterMT"
                clearable
              ></el-input>
              <button @click="selectAll4" style="height: 24px; width: 70px; margin-left: 19px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 20px" @click="qinchu4">清空</button>
              <el-option v-for="item in list4" :key="item" :label="item" :value="item"> </el-option>
              <el-option v-if="list4 == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 160px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in QQ.mt" :key="item">
                <el-tag closable @close="handleCloseMT(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
          <el-button type="primary" plain icon="el-icon-search" @click="handleSearch">搜索</el-button>
          <el-button v-if="fanhui" type="primary" plain icon="el-icon-search" @click="fanhuiss">返回全部</el-button>
        </div>
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">快速检索</span>
          <el-input
            v-model="query"
            placeholder="空格隔开筛选项"
            clearable
            style="width:240px;margin-right:10px;"
          ></el-input>
          <el-button
            @click="download"
            style="margin-left: auto"
            icon="el-icon-s-release"
            type="info"
            plain
          >导出csv</el-button>
        </div>
      </div>

      <el-table
        v-loading="pictLoading"
        element-loading-text="数据正在加载中"
        :data="search(query).slice((pageInfo.current - 1) * pageInfo.size, pageInfo.current * pageInfo.size)"
        height="550"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        :header-cell-style="{ color: '#696969' }"
      >
        <ex-table-column
          :autoFit="true"
          :fitHeader="true"
          sortable
          v-for="item in tableColumns1"
          :key="item"
          :prop="item"
          fixed
          :label="item"
        />

        <ex-table-column
          :autoFit="true"
          :fitHeader="true"
          sortable
          v-for="item in tableColumns2"
          :key="item"
          :prop="item"
          :label="item"
        />
      </el-table>

      <pagination
        @size-change="handleSizeChange"
        :current-page.sync="pageInfo.current"
        :page-size.sync="pageInfo.size"
        :total="pageInfo.total"
      ></pagination>

      <el-dialog title="个人消耗统计模块" :visible.sync="dialogVisible" width="30%">
        <div style="margin-top: -20px">
          <h2>分组条件</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>日期 （默认选择，不显示）</li>
            <li>项目</li>
            <li>渠道</li>
            <li>国家</li>
            <li>日期范围</li>
            <li>人员</li>
          </ul>
          <h2>筛选条件</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>日期</li>
            <li>项目</li>
            <li>渠道</li>
            <li>国家</li>
            <li>日期范围</li>
            <li>人员</li>
          </ul>
          <h2 style="margin-top: 20px">数据指标</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>消耗</li>
            <li>安装</li>
            <li>CPI</li>
          </ul>
          <h2 style="margin-top: 20px">说明</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>搜索条件对大小写不敏感，us US Us 均可搜索到US地区的数据</li>
            <li>快速检索：输入字段进行检索，支持多字段检索（以空格分隔输入，例：2020-10-01 火柴人神射手 ios）</li>
          </ul>
        </div>

        <!-- <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span> -->
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import echarts from 'echarts';
import { fetchData } from '../../api/index';
import json2csv from 'json2csv';
import cw403 from './403.vue';
export default {
  name: 'market-table3',
  components: {
    cw403
  },
  data() {
    const generateData = (_) => {
      const data = [];
      const xuanxiang = ['项目', '渠道', '国家', '人员', '媒体渠道'];
      for (let i = 0; i <= 4; i++) {
        data.push({
          key: xuanxiang[i],
          label: xuanxiang[i]
        });
      }
      return data;
    };
    return {
      inputXM: '',
      inputGZ: '',
      inputRY: '',
      inputMT: '',
      user_group: localStorage.getItem('user-group'),
      checkList: ['项目'],
      //穿梭框参数
      tableColumns: [],
      tableColumns1: [],
      tableColumns2: [],
      data: generateData(),
      fenzuList: [],
      //折线图参数
      time: [],
      active1: [],
      active2: [],
      addmoney1: [],
      addmoney2: [],
      fanhui: false,
      checked0: false,
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      pictLoading: true,
      pageInfo: {
        size: 50,
        current: 1,
        total: 0,
      },
      date: [],

      dialogVisible: false,
      type: false,
      listn: [],
      list1: [],
      list2: [
        { label: '所有平台', value: 'all' },
        { label: 'IOS', value: 'ios' },
        { label: 'Android', value: 'android' }
      ],
      list3: [],
      list4: [],
      QQ: {
        date: [],
        man: [],
        gameName: [],
        phone: [],
        country: [],
        mt: []
      },
      query: '',
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1
    };
  },
  created() {
    /* this.getData(); */
    this.getTable();
    this.getXM();
    this.getGZ();
    this.getMan();
    this.PageCount();
  },
  methods: {
    //回车选中第一项方法
    enterXM() {
      if (this.QQ.gameName.includes(this.list1[0])) {
        console.log('已选中');
      } else this.QQ.gameName.push(this.list1[0]);
      this.getGZ();
    },
    enterGZ() {
      if (this.QQ.country.includes(this.list3[0])) {
        console.log('已选中');
      } else this.QQ.country.push(this.list3[0]);
      this.getMT();
    },
    enterRY() {
      if (this.QQ.man.includes(this.listn[0])) {
        console.log('已选中');
      } else this.QQ.man.push(this.listn[0]);
      this.getXM();
    },
    enterMT() {
      if (this.QQ.mt.includes(this.list4[0])) {
        console.log('已选中');
      } else this.QQ.mt.push(this.list4[0]);
    },
    handleClose(tag) {
      this.QQ.gameName.splice(this.QQ.gameName.indexOf(tag), 1);
    },
    handleCloseman(tag) {
      this.QQ.man.splice(this.QQ.man.indexOf(tag), 1);
    },
    handleCloseGZ(tag) {
      this.QQ.country.splice(this.QQ.country.indexOf(tag), 1);
    },
    handleCloseMT(tag) {
      this.QQ.mt.splice(this.QQ.mt.indexOf(tag), 1);
    },
    demo(val) {
      this.checkList = val;
    },
    //返回按钮
    fanhuiss() {
      (this.time = []), (this.addmoney1 = []), (this.addmoney2 = []), (this.active1 = []), (this.active2 = []), (this.pictLoading = true);
      this.QQ.gameName = '';
      this.QQ.phone = '';
      this.QQ.country = '';
      this.getTable();

      this.fanhui = false;
    },
    //添加全选功能
    selectAll0() {
      this.QQ.man = this.listn;
      this.getXM();
    },
    qinchu0() {
      this.QQ.man = [];
      this.checked0 = false;
    },
    selectAll1() {
      this.QQ.gameName = this.list1;
      this.getGZ();
    },
    qinchu1() {
      this.QQ.gameName = [];
      this.checked1 = false;
    },
    selectAll2() {
      this.QQ.phone = [];
      if (this.checked2) {
        this.QQ.phone = ['all', 'ios', 'android'];
      } else this.QQ.phone = [];
      this.getGZ();
    },
    selectAll3() {
      this.QQ.country = this.list3;
      this.getMT();
    },
    qinchu3() {
      this.QQ.country = [];
      this.checked3 = false;
    },
    selectAll4() {
      this.QQ.mt = this.list4;
    },
    qinchu4() {
      this.QQ.mt = [];
      this.checked4 = false;
    },
    getMan() {
      //发起get请求
      this.$http
        .get('member_consume/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          this.listn = res.data.member_data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getTable() {
      //发起get请求
      this.$http
        .get('member_consume/init_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.tableData = res.data.list;
            this.pageInfo.total = res.data.list.length;
            this.tableColumns = [];
            for (var pro in res.data.list[0]) {
              this.tableColumns.push(pro);
            }
            this.tableColumns1 = this.tableColumns.splice(0, 1);
            this.tableColumns2 = this.tableColumns;
            this.pictLoading = false;
          }
        })
        .catch(function (err) {
          console.log(err);
        });

      /* this.$axios({
                method:'get',
                url:'http://192.168.1.29:8085/basicdata/test/'
            }).then((response) =>{          //这里使用了ES6的语法
                console.log(response)       //请求成功返回的数据
            }).catch((error) =>
                console.log(error)       //请求失败返回的数据
            ) */
    },

    // 获取 easy-mock 的模拟数据
    getData() {
      fetchData(this.query).then((res) => {
        /* console.log(res); */
        this.tableData = res.list;
        this.pageTotal = res.pageTotal || 50;
        this.$nextTick(() => {
          this.$refs.multipleTable.doLayout();
          // el-table加ref="multipleTable"
        });
      });
    },
    // 触发搜索按钮
    handleSearch() {
      this.fanhui = true;
      var a = this.date;
      var b = [];
      var c = [];
      var d = [];
      var m = [];
      var n = this.QQ.mt;
      var g1 = [];
      var g2 = [];
      var g3 = [];
      var g4 = [];
      var g5 = [];
      if (this.QQ.man == '') m = [];
      else m = this.QQ.man;

      if (this.QQ.gameName == '') b = ['all'];
      else b = this.QQ.gameName;
      if (this.QQ.phone == '') c = ['all', 'ios', 'android'];
      else c = this.QQ.phone;
      if (this.QQ.country == '') {
        d = ['all'];
      } else d = this.QQ.country;

      if (this.checkList[0] == undefined) g1 = [];
      else g1 = [this.checkList[0]];
      if (this.checkList[1] == undefined) g2 = [];
      else g2 = [this.checkList[1]];
      if (this.checkList[2] == undefined) g3 = [];
      else g3 = [this.checkList[2]];
      if (this.checkList[3] == undefined) g4 = [];
      else g4 = [this.checkList[3]];
      if (this.checkList[4] == undefined) g5 = [];
      else g5 = [this.checkList[4]];

      this.pictLoading = true;
      //post请求获取搜索内容
      this.$axios
        .post(
          'member_consume/info/',
          {
            member: m,
            project: b,
            platform: c,
            date_range: a,
            country: d,
            media: n,
            group_1: g1,
            group_2: g2,
            group_3: g3,
            group_4: g4,
            group_5: g5
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.tableData = res.data.list;
          this.pageInfo.total = res.data.list.length;
          this.tableColumns = [];
          for (var pro in res.data.list[0]) {
            this.tableColumns.push(pro);
          }
          this.tableColumns1 = this.tableColumns.splice(0, res.data.fix_columns);
          this.tableColumns2 = this.tableColumns;
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout();
            // el-table加ref="multipleTable"
          });
          this.pictLoading = false;
        })
        .catch((e) => {});
    },
    handleSizeChange() {
      this.pageInfo.current = 1;
    },
    //搜索
    search(query) {
      let tableData = this.tableData.filter((item) => {
        var array = query.split(' ');
        if (array[1] == undefined) array[1] = '';
        if (array[2] == undefined) array[2] = '';
        if (array[3] == undefined) array[3] = '';
        if (array[4] == undefined) array[4] = '';

        if (
          JSON.stringify(item).includes(array[0]) &&
          JSON.stringify(item).includes(array[1]) &&
          JSON.stringify(item).includes(array[2]) &&
          JSON.stringify(item).includes(array[3]) &&
          JSON.stringify(item).includes(array[4])
        )
          return item;
      });
      this.pageInfo.total = tableData.length;
      return tableData
    },
    //导出csv文件
    download() {
      //datas：数据，fields：字段名
      try {
        const result = json2csv.parse(this.tableData);
        // 判断浏览器类型
        if (
          (navigator.userAgent.indexOf('compatible') > -1 && navigator.userAgent.indexOf('MSIE') > -1) ||
          navigator.userAgent.indexOf('Edge') > -1
        ) {
          //IE10或Edge浏览器
          var BOM = '\uFEFF';
          var csvData = new Blob([BOM + result], { type: 'text/csv' });
          navigator.msSaveBlob(csvData, `test.csv`);
        } else {
          //非IE浏览器
          var csvContent = 'data:text/csv;charset=utf-8,\uFEFF' + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement('a');
          link.href = encodeURI(csvContent);
          link.download = `test.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    selectRY(val) {
      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === this.listn) {
        this.checked0 = true;
      } else this.checked0 = false;
      this.getXM();
    },
    selectXM(val) {
      this.getMT();

      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === this.list1) {
        this.checked1 = true;
      } else this.checked1 = false;
      if (this.QQ.gameName != '') {
        this.$axios
          .post(
            'member_consume/country_data/',
            {
              project: this.QQ.gameName,
              platform: ['all', 'ios', 'android'],
              member: this.QQ.man
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
          })
          .catch((e) => {});
      }
      if (this.QQ.gameName == '') {
        this.$axios
          .post(
            'member_consume/country_data/',
            {
              project: this.list1,
              platform: ['all', 'ios', 'android'],
              member: this.QQ.man
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
          })
          .catch((e) => {});
      }
    },
    selectPT(val) {
      this.getMT();

      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === ['all', 'ios', 'android']) {
        this.checked2 = true;
      } else this.checked2 = false;
      if (this.QQ.phone != '' && this.QQ.phone != '') {
        this.$axios
          .post(
            'member_consume/country_data/',
            {
              project: this.QQ.gameName,
              platform: this.QQ.phone,
              member: this.QQ.man
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
          })
          .catch((e) => {});
      }
      if (this.QQ.gameName == '' && this.QQ.phone != '') {
        this.$axios
          .post(
            'member_consume/country_data/',
            {
              project: this.list1,
              platform: this.QQ.phone,
              member: this.QQ.man
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
          })
          .catch((e) => {});
      }
      if (this.QQ.gameName != '' && this.QQ.phone == '') {
        this.$axios
          .post(
            'member_consume/country_data/',
            {
              project: this.QQ.gameName,
              platform: ['all', 'ios', 'android'],
              member: this.QQ.man
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
          })
          .catch((e) => {});
      }
      if (this.QQ.gameName == '' && this.QQ.phone == '') {
        this.$axios
          .post(
            'member_consume/country_data/',
            {
              project: this.list1,
              platform: ['all', 'ios', 'android'],
              member: this.QQ.man
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
          })
          .catch((e) => {});
      }
    },
    selectGZ(val) {
      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === this.list3) {
        this.checked3 = true;
      } else this.checked3 = false;
      this.getMT();
    },
    //获取游戏名
    getXM() {
      this.$axios
        .post(
          'member_consume/project_data/',
          {
            member: this.QQ.man
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.list1 = res.data.project_data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    //获取所有国家信息
    getMT() {
      if (this.QQ.gameName != '' && this.QQ.phone != '' && this.QQ.man != '' && this.QQ.country != '') {
        this.$axios
          .post(
            'member_consume/media_data/',
            {
              project: this.QQ.gameName,
              platform: this.QQ.phone,
              member: this.QQ.man,
              country: this.QQ.country
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list4 = res.data.media_data;
          })
          .catch((e) => {});
      }
    },
    getGZ() {
      //Vue.http.options.emulateJSON = true;

      this.$http
        .get('member_consume/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          this.list1 = res.data.project_data;

          this.$axios
            .post(
              'member_consume/country_data/',
              {
                project: this.QQ.gameName,
                platform: ['all', 'ios', 'android'],
                member: this.QQ.man
              },
              {
                headers: {
                  Authorization: 'JWT ' + localStorage.getItem('jwt-token')
                }
              }
            )
            .then((res) => {
              this.list3 = res.data.country_data;
            })
            .catch((e) => {});
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    // 访问
    PageCount() {
      this.$http
        .post('index/count/', 
        {
          page_name:"个人的消耗统计"
        },
        {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            // this.$refs.childProject.project_list = res.data.project_data;
            // this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log('Auth Error');
        if (localStorage.getItem("jwt-token") != null){
          localStorage.clear();
          window.location.href="/#/login";
        } 
        });
    },
    // 获取之前的日期的函数
    getBeforeDate(n) {
          let _n = n;
          let _d = new Date();
          let _year = _d.getFullYear();
          let _mon = _d.getMonth() + 1;
          let _day = _d.getDate();
          if(_day <= _n) {
              if(_mon > 1) {
                  _mon = _mon - 1;
              } else {
                  _year = _year - 1;
                  _mon = 12;
              }
          }
          _d.setDate(_d.getDate() - n);
          _year = _d.getFullYear();
          _mon = _d.getMonth() + 1;
          _day = _d.getDate();
          var date_sub = _year + "-" + (_mon < 10 ? ('0' + _mon) : _mon) + "-" + (_day < 10 ? ('0' + _day) : _day);
          return date_sub;
      }
  },
  mounted() {
    let timeStamp = new Date();
    let year = new Date(timeStamp).getFullYear();
    let month = new Date(timeStamp).getMonth() + 1 < 10 ? '0' + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1;
    let month1 = new Date(timeStamp).getMonth() < 10 ? '0' + new Date(timeStamp).getMonth() : new Date(timeStamp).getMonth();
    let date = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate();
    let date_sub = this.getBeforeDate(30);
    this.date = [date_sub, year + '-' + month + '-' + date];
  },
  watch: {
    inputXM() {
      var newlist1 = [];
      this.list1.filter((item) => {
        if (item.toUpperCase().includes(this.inputXM.toUpperCase())) {
          newlist1.push(item);
        }
      });
      this.list1 = newlist1;
      if (this.inputXM == '') {
        this.getXM();
      }
    },

    inputGZ() {
      var newlist3 = [];
      this.list3.filter((item) => {
        if (item.toUpperCase().includes(this.inputGZ.toUpperCase())) {
          newlist3.push(item);
        }
      });
      this.list3 = newlist3;
      if (this.inputGZ == '') {
        this.getGZ();
      }
    },
    inputRY() {
      var newlistn = [];
      this.listn.filter((item) => {
        if (item.toUpperCase().includes(this.inputRY.toUpperCase())) {
          newlistn.push(item);
        }
      });
      this.listn = newlistn;
      if (this.inputRY == '') {
        this.getMan();
      }
    },
    inputMT() {
      var newlist4 = [];
      this.list4.filter((item) => {
        if (item.toUpperCase().includes(this.inputMT.toUpperCase())) {
          newlist4.push(item);
        }
      });
      this.list4 = newlist4;
      if (this.inputMT == '') {
        this.getMT();
      }
    }
  }
};
</script>
<style>
.el-tag .el-icon-close {
  float: right;
  margin-top: 4px;
}
.el-tag {
  background: white;
  width: 100%;
}
.el-table .cell,
.el-table th > .cell {
  white-space: nowrap;
  width: fit-content;
}
.el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.el-tag.el-tag--info .el-tag__close {
  display: none;
}
.el-tag.el-tag--info {
  border: none;
  background: white;
}
.el-select__tags-text {
  margin-left: 0px;
}
.el-date-editor--daterange.el-input__inner {
  width: 240px;
}
.el-picker-panel {
  margin-left: 240px;
}
.el-transfer-panel {
  width: 150px;
  height: 200px;
}
.el-transfer-panel__list.is-filterable {
  height: 200px;
}
</style>
<style scoped>
.table {
  width: 100%;
  font-size: 14px;
}
</style>