<template>
  <div>
    <div v-if="user_group.indexOf('测试组') == -1 && user_group.indexOf('产品组') == -1">
      <cw403></cw403>
    </div>
    <div v-if="user_group.indexOf('测试组') > -1 || user_group.indexOf('产品组') > -1">
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            素材标记
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container">
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">筛选维度</span>
          <el-select v-model="project" @change="search_media" filterable placeholder="项目" collapse-tags style="margin-right: 10px; width: 180px">  
            <el-option v-for="item in projects" :key="item" :label="item" :value="item"></el-option>
          </el-select>
          <el-select v-model="platform" @change="search_media" placeholder="平台" collapse-tags style="margin-right: 10px; width: 180px">
            <el-option v-for="item in platforms" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-select v-model="media" placeholder="渠道" multiple collapse-tags style="margin-right: 10px; width: 180px">
            <el-option v-for="item in medias" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
          <el-button type="primary" plain icon="el-icon-search" @click="handleSearch" :loading="loadingT">
            搜索
          </el-button>
        </div> 
        <el-table
        ref="filterTable"
        :data="tableData"
        style="width: 100%">
        <af-table-column
          prop="app_id"
          label="应用id"
          sortable
          width="180"
        >
        </af-table-column>
        <af-table-column
          prop="media_source"
          label="媒体渠道"
          width="180">
        </af-table-column>
        <af-table-column
          prop="af_ad"
          label="广告素材"
          width="180">
        </af-table-column>
        <af-table-column
          label="预测标签"
          width="180">
          <template slot-scope="scope">
            <el-tag 
            size="medium"
            v-if="scope.row.tag.indexOf(scope.row.pre_tag) > -1"
            class='bkblue'
            @click="change_class(scope.$index)">{{ scope.row.pre_tag }}</el-tag>
            <el-tag 
            size="medium"
            v-else
            class='bknone'
            @click="change_class(scope.$index)">{{ scope.row.pre_tag }}</el-tag>
          </template>
        </af-table-column>
        <af-table-column
          prop="tag"
          label="标签"
          align="right">
          <template slot-scope="scope">
            
            <el-tag
            style="width: 50"
            :key="info"
            v-for="info in scope.row.tag"
            closable
            :disable-transitions="false"
            @close="handleClose(info,scope.$index)">
            {{info}}
            </el-tag>
            
            <el-input
            style="width: 50"
            v-if="scope.row.show"
            id = "test"
            v-model = inputValue
            size="small"
            @keyup.enter.native="handleInputConfirm(scope.$index, scope.row)"
            @blur = "handleInputConfirm(scope.$index, scope.row)"
            >
            </el-input>
            
            <el-button v-else style="width: 50" class="button-new-tag" id= "" size="small" @click="showInput(scope.$index, scope.row)">+ New Tag</el-button>
            
          
          </template>
        </af-table-column>
      </el-table>
      </div>


    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { fetchData } from '../../api/index';
import json2csv from 'json2csv';
import cw403 from './403.vue';
import AFTableColumn from 'af-table-column'
Vue.use(AFTableColumn) 
import ElSearchInput from '../SelectComponent/searchInput.vue';
export default {
  name: 'market-pixel',
  components: {
    cw403,
    ElSearchInput
  },
   data() {
      return {
        projects: [],
        project: '',
        medias: [],
        media: '',
        platforms: [
          { label: 'IOS', value: 'ios' },
          { label: 'Android', value: 'android' }
        ],
        platform: '',
        user_group: localStorage.getItem('user-group'),
        inputVisible: false,
        inputValue: '',
        tableData: []
      }
    },
    created() {
      this.init();
      this.PageCount();
    },
    methods: {
      init(){
        this.$http
          .get('pixel/project_data/', {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          })
          .then(function (res) {
            if (res.data.code == 200) {
              this.projects = res.data.project_data;
            }
          })
          .catch(function (err) {
            this.$notify.error({
              title: '初始化错误',
              message: '请求项目数据出错',
              duration: 1200
            });
          });
      },
      search_media(){
        //post请求获取搜索内容
        this.$axios
          .post(
            'pixel/media_source/',
            {
              project: this.project,
              platform: this.platform
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            if (res.data.code == 200) {
              this.medias = res.data.media;
            }
          })
          .catch((e) => {});
      },
      handleSearch(){
        this.$axios
          .post(
            'pixel/pixel_tag/',
            {
              project: this.project,
              platform: this.platform,
              media: this.media
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            if (res.data.code == 200) {
              this.tableData = res.data.list;
            }
          })
          .catch((e) => {});
      },
      updateTag(index){
        this.$axios
          .post(
            'pixel/tag_update/',
            {
              app_id: this.tableData[index].app_id,
              ad: this.tableData[index].af_ad,
              media: this.tableData[index].media_source,
              tag: this.tableData[index].tag
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            if (res.data.code == 200) {
              this.$notify({
                title: '成功',
                message: '更新成功',
                type: 'success',
                duration: 500
              });
            }
          })
          .catch((e) => {
            this.$notify({
              title: '错误',
              message: 'tag更新失败'
            });
          });
      },
      change_class(index){
        if (this.tableData[index].tag.indexOf(this.tableData[index].pre_tag) > -1){
          this.tableData[index].tag.splice(this.tableData[index].tag.indexOf(this.tableData[index].pre_tag), 1);
          this.updateTag(index);
        }else {
          this.tableData[index].tag.push(this.tableData[index].pre_tag);
          this.updateTag(index);
        };
      },
      resetDateFilter() {
        this.$refs.filterTable.clearFilter('date');
      },
      clearFilter() {
        this.$refs.filterTable.clearFilter();
      },
      formatter(row, column) {
        return row.address;
      },
      filterTag(value, row) {
        return row.tag === value;
      },
      filterHandler(value, row, column) {
        const property = column['property'];
        return row[property] === value;
      },
      handleEdit(index, row) {
        console.log(index, row);
      },
      handleDelete(index, row) {
        console.log(index, row);
      },
      handleClose(tag,index) {
        this.tableData[index].tag.splice(this.tableData[index].tag.indexOf(tag), 1);
        this.updateTag(index);
      },
      showInput(index,row) {
        this.tableData[index].show = true;    
        this.$nextTick(_ => {
          document.getElementById("test").focus();    
        });
      },

      fffo(){
        
      },

      handleInputConfirm(index, row) {
        var input_point = document.getElementById("test");
        input_point.style.display = "none";
        let inputValue = this.inputValue;
        if (inputValue){
          this.tableData[index].tag.push(inputValue);
          this.updateTag(index);
          this.inputValue = '';
        }
        this.tableData[index].show = false;
      },
      // 访问
      PageCount() {
        this.$http
          .post('index/count/', 
          {
            page_name:"素材标记"
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          })
          .then(function (res) {
            if (res.data.code == 200) {
              // this.$refs.childProject.project_list = res.data.project_data;
              // this.list1.unshift('all');
            }
          })
          .catch(function (err) {
            console.log('Auth Error');
        if (localStorage.getItem("jwt-token") != null){
          localStorage.clear();
          window.location.href="/#/login";
        } 
          });
      },
  },


  mounted() {
  },
  watch: {
  }
};
</script>
<style>
.el-tag .el-icon-close {
  float: right;
  margin-top: 4px;
}
.el-tag {
  background: white;
  /* width: 25%; */
}
.elx-header--column.col--ellipsis > .elx-cell .elx-cell--title {
  overflow: initial;
  text-overflow: ellipsis;
  white-space: normal;
}
.elx-table {
  font-size: 12px;
}
.elx-table .elx-header--column {
  position: relative;
  line-height: 1;
  text-align: left;
}
.el-table .cell,
.el-table th > .cell {
  white-space: normal;
  width: fit-content;
}
.el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.el-tag.el-tag--info .el-tag__close {
  display: none;
}
.el-tag.el-tag--info {
  border: none;
  background: white;
}
.el-select__tags-text {
  margin-left: 0px;
}
.el-date-editor--daterange.el-input__inner {
  width: 240px;
}
.el-picker-panel {
  margin-left: 240px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
  /* display: none; */
}
.bkblue{
  background-color : lightcyan;
}
.bknone{
  background-color : rgb(250, 252, 252);
}
</style>
<style scoped>
.table {
  width: 100%;
  font-size: 14px;
  margin-top: 20px;
}
</style>
