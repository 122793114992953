<template>
  <div>
    <div v-if="user_group.indexOf('测试组') == -1 && user_group.indexOf('产品组') == -1">
      <cw403></cw403>
    </div>
    <div v-if="user_group.indexOf('测试组') > -1 || user_group.indexOf('产品组') > -1">
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            总数据
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container">
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">快速检索</span>
          <ElSearchInput ref="searchInput" @SearchInput='SearchInput($event)'></ElSearchInput>
          <el-button
            @click="download"
            style="margin-left: auto"
            icon="el-icon-s-release"
            type="info"
            plain
          >导出csv</el-button>
        </div>
      </div>
      <el-table
        v-loading="pictLoading"
        element-loading-text="数据正在加载中"
        :data="tableSearchList.slice((pageInfo.current-1)*pageInfo.size,pageInfo.current*pageInfo.size)"
        :current-page.sync="pageInfo.current"
        height="550"
        border
        class="table"
        ref="multipleTable"
        :header-cell-style="{ color: '#696969' }"
      >
        <af-table-column
          :min-width="120"
          sortable
          v-for="item in tableColumns1"
          :key="item"
          :prop="item"
          fixed
          :label="item"
        />

        <af-table-column
          sortable
          v-for="item in tableColumns2"
          :key="item"
          :prop="item"
          :label="item"
        />
      </el-table>

      <pagination
        @size-change="handleSizeChange"
        :current-page.sync="pageInfo.current"
        :page-size.sync="pageInfo.size"
        :total="pageInfo.total"
      ></pagination>

    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import AFTableColumn from 'af-table-column'
Vue.use(AFTableColumn) 
import json2csv from 'json2csv';
import cw403 from './403.vue';
import ElSearchInput from '../SelectComponent/searchInput.vue';
export default {
  name: 'gm-main',
  components: {
    cw403,
    ElSearchInput
  },
  data() {
    return {
      tableSearchList: [],
      user_group: localStorage.getItem('user-group'),
      tableColumns: [],
      tableColumns1: [],
      tableColumns2: [],
      //折线图参数
      time: [],
      active1: [],
      active2: [],
      addmoney1: [],
      addmoney2: [],
      fanhui: false,
      checked1: false,
      checked2: false,
      checked3: false,
      checked5: false,
      checked6: false,
      checked7: false,
      checked9: false,
      pictLoading: true,
      pageInfo: {
        size: 200,
        current: 1,
        total: 1000,
      },
      date: [],
      dialogVisible: false,
      type: false,
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1
    };
  },
  created() {
    /* this.getData(); */
    this.getTable();
    this.PageCount();
  },
  computed: {
      // 计算属性对数据进行处理
      frontEndPageChange() {
       let start = (this.pageInfo.current - 1) * this.pageInfo.size;
       if (start >= this.tableData.length) start = 0;
       let end = this.pageInfo.current * this.pageInfo.size;
       if (end >= this.tableData.length) end = this.tableData.length;
       return this.tableData.slice(start, end);
     }
    },

  methods: {
    //checkbox-group回调
    demo(val) {
      this.checkList = val;
    },

    getTable() {
      //发起get请求
      this.$http
        .get('gm/main_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.tableData = res.data.list;
            this.pageInfo.total = res.data.list.length;
            this.tableColumns = [];
            for (var pro in res.data.list[0]) {
              this.tableColumns.push(pro);
            }
            this.tableColumns1 = this.tableColumns.splice(0, 1);
            this.tableColumns2 = this.tableColumns;
            this.pictLoading = false;
            this.$refs.searchInput.searchTable();
          }
        })
        .catch(function (err) {
          this.pictLoading = false;
          console.log(err);
          this.$message.error('请求出错, 将在5S后重试, 重试失败请联系管理员');
          setTimeout(() => {
            this.getTable();
          }, 5000);
          // console.log(err);
        });

      /* this.$axios({
                method:'get',
                url:'http://192.168.1.29:8085/basicdata/test/'
            }).then((response) =>{          //这里使用了ES6的语法
                console.log(response)       //请求成功返回的数据
            }).catch((error) =>
                console.log(error)       //请求失败返回的数据
            ) */
    },
    handleSizeChange(val) {
      this.pageInfo.size = val;
    },
        // 改变当前页
    handlePageChange(val) {
      this.pageInfo.current = val;
    },
    // 检索
    SearchInput(tableList){
      this.tableSearchList = tableList;
      this.pageInfo.total = tableList.length;
    },
    //导出csv文件
    download() {
      //datas：数据，fields：字段名
      try {
        const result = json2csv.parse(this.tableData);
        // 判断浏览器类型
        if (
          (navigator.userAgent.indexOf('compatible') > -1 && navigator.userAgent.indexOf('MSIE') > -1) ||
          navigator.userAgent.indexOf('Edge') > -1
        ) {
          //IE10或Edge浏览器
          var BOM = '\uFEFF';
          var csvData = new Blob([BOM + result], { type: 'text/csv' });
          navigator.msSaveBlob(csvData, `test.csv`);
        } else {
          //非IE浏览器
          var csvContent = 'data:text/csv;charset=utf-8,\uFEFF' + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement('a');
          link.href = encodeURI(csvContent);
          link.download = `test.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
  // 访问
    PageCount() {
      this.$http
        .post('index/count/', 
        {
          page_name:"gm-main"
        },
        {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            // this.$refs.childProject.project_list = res.data.project_data;
            // this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log('Auth Error');
          if (localStorage.getItem("jwt-token") != null){
            localStorage.clear();
            window.location.href="/#/login";
          } 
        });
    },
  // 获取之前的日期的函数
  getBeforeDate(n) {
          let _n = n;
          let _d = new Date();
          let _year = _d.getFullYear();
          let _mon = _d.getMonth() + 1;
          let _day = _d.getDate();
          if(_day <= _n) {
              if(_mon > 1) {
                  _mon = _mon - 1;
              } else {
                  _year = _year - 1;
                  _mon = 12;
              }
          }
          _d.setDate(_d.getDate() - n);
          _year = _d.getFullYear();
          _mon = _d.getMonth() + 1;
          _day = _d.getDate();
          var date_sub = _year + "-" + (_mon < 10 ? ('0' + _mon) : _mon) + "-" + (_day < 10 ? ('0' + _day) : _day);
          return date_sub;
      }
  },

  mounted() {
    let timeStamp = new Date();
    let year = new Date(timeStamp).getFullYear();
    let month = new Date(timeStamp).getMonth() + 1 < 10 ? '0' + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1;
    let month1 = new Date(timeStamp).getMonth() < 10 ? '0' + new Date(timeStamp).getMonth() : new Date(timeStamp).getMonth();
    let date = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate();
    let date_sub = this.getBeforeDate(30);
    this.date = [date_sub, year + '-' + month + '-' + date];
  },

  watch: {
  }
};
</script>
<style>
.el-tag .el-icon-close {
  float: right;
  margin-top: 4px;
}
.el-tag {
  background: white;
  width: 100%;
}
.el-table .cell,
.el-table th > .cell {
  white-space: nowrap;
  width: fit-content;
}
.el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.el-tag.el-tag--info .el-tag__close {
  display: none;
}
.el-tag.el-tag--info {
  border: none;
  background: white;
}
.el-select__tags-text {
  margin-left: 0px;
}
.el-date-editor--daterange.el-input__inner {
  width: 240px;
}
.el-picker-panel {
  margin-left: 240px;
}
</style>
<style scoped>
.table {
  width: 100%;
  font-size: 14px;
}
</style>