import { render, staticRenderFns } from "./market-lofInfo.vue?vue&type=template&id=63e1263c&scoped=true&"
import script from "./market-lofInfo.vue?vue&type=script&lang=js&"
export * from "./market-lofInfo.vue?vue&type=script&lang=js&"
import style0 from "./market-lofInfo.vue?vue&type=style&index=0&id=63e1263c&prod&lang=css&"
import style1 from "./market-lofInfo.vue?vue&type=style&index=1&id=63e1263c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63e1263c",
  null
  
)

export default component.exports