<template>
  <div>
    <div v-if="user_group.indexOf('测试组') == -1 && user_group.indexOf('市场组') == -1">
      <cw403></cw403>
    </div>
    <div v-if="user_group.indexOf('测试组') > -1 || user_group.indexOf('市场组') > -1">
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            UAC2.5事件分析
            <el-button type="text" style="font-size: 16px" @click="dialogVisible = true">(页面说明)</el-button>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container">
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">汇总维度</span>
          <template>
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="日期" ></el-checkbox>
              <!-- <el-checkbox label="媒体渠道" disabled></el-checkbox> -->
              <!-- <el-checkbox label="事件名称" disabled></el-checkbox> -->
            </el-checkbox-group>
          </template>
        </div>
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">筛选维度</span>
          <date-picker
            style="margin-right: 10px"
            v-model="date"
          >
          </date-picker>
          <el-select v-model="projectName" filterable placeholder="项目" collapse-tags style="margin-right: 10px; width: 180px">
            <el-option v-for="item in projectList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
          <el-select @change="checkCampaign" v-model="platform" collapse-tags style="margin-right: 10px; width: 180px">
            <el-option v-for="item in platformList" :key="item.vaue" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <!-- <el-select v-model="QQ.media" multiple placeholder="默认不筛选买量渠道" collapse-tags style="margin-right: 10px; width: 180px">
            <el-option v-for="item in list3" :key="item.vaue" :label="item.label" :value="item.value"> </el-option>
          </el-select> -->
          <el-button type="primary" plain icon="el-icon-search" @click="handleSearch" :loading="searchLoading"
            >搜索</el-button
          >
          <el-button v-if="fanhui" type="primary" plain icon="el-icon-search" @click="fanhuiss">清空筛选</el-button>
        </div>
        <div class="options-bar">
          <span style="16px;color:gray;margin-right:30px;float:left;">快速检索</span>
          <ElSearchInput ref="searchInput" @SearchInput='SearchInput($event)'></ElSearchInput>
          <span style="12px;color:gray;margin-right:30px;float:left;"></span>
          <el-button
            @click="download"
            style="margin-left: auto"
            icon="el-icon-s-release"
            type="info"
            plain
            >导出csv</el-button
          >
        </div>
        <div v-if="show_info">
          <el-table
            v-loading="pictLoading"
            element-loading-text="数据正在加载中"
            :data="tableSearchList.slice((pageInfo.current-1)*pageInfo.size,pageInfo.current*pageInfo.size)"
            :key="campaign"
            height="550"
            fix=true
            border
            class="table"
            ref="multipleTable"
            highlight-current-row=true
            header-cell-class-name="table-header"
            :header-cell-style="{ color: '#696969' }"
          >
            <af-table-column
              sortable
              v-for="item in tableColumns1"
              :key="item"
              :prop="item"
              fixed
              :label="item"
            />
            <af-table-column
              sortable
              v-for="item in tableColumnsd"
              :key="item"
              :prop="item"
              fixed
              :label="item"
            />

            <af-table-column
              v-for="item in tableColumns2"
              :key="item"
              :prop="item"
              :label="item"
            />
          </el-table>
          <pagination
            @size-change="handleSizeChange"
            :current-page.sync="pageInfo.current"
            :page-size.sync="pageInfo.size"
            :total="pageInfo.total"
          ></pagination>

        </div>
        <div v-if="show_campaign">
          <el-table
            :data="null_campaign"
            :key="null_c"
            style="width: 100%">
            <el-table-column
              label="计划名称"
              prop="campaign_name"
              sortable
              width="380"
            >
            </el-table-column>
            <el-table-column
              label="投放事件"
              width="380">
              <template slot-scope="scope">
                <div class="name-wrapper" v-for="(item, index) in scope.row.event_list">
                  <el-tag style="width:70%" size="medium" closable @close="handleClose(index,scope.row)">{{ item }}</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="插入事件" prop='inputValue'>
              <template slot-scope="scope">
                <el-input style="width:50%" v-model="scope.row.inputValue" placeholder="请输入事件"></el-input>
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleInsert(scope.row)">插入事件</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <el-dialog title="UAC2.5事件分析模块" :visible.sync="dialogVisible" width="30%">
        <div style="margin-top: -20px">
          <h2>WaitUpdateLater</h2>
        </div>

        <!-- <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span> -->
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { fetchData } from '../../api/index';
import AFTableColumn from 'af-table-column'
Vue.use(AFTableColumn) 
import json2csv from 'json2csv';
import cw403 from './403.vue';
import ElSearchInput from '../SelectComponent/searchInput.vue';
export default {
  name: 'product-table3',
  components: {
    cw403,
    ElSearchInput
  },
  data() {
    return {
      checkList: ["日期"],
      show_info: false,
      show_campaign: false,
      inputXM: '',
      user_group: localStorage.getItem('user-group'),
      searchLoading: false,
      null_campaign: [],
      tableSearchList: [],
      tableColumns: [],
      tableColumns1: [],
      tableColumns2: [],
      tableColumnsd: [],
      fanhui: false,
      checked1: false,
      checked2: false,
      checked3: false,
      pictLoading: false,
      pageInfo: {
        size: 50,
        current: 1,
        total: 0,
      },
      date: [],

      dialogVisible: false,
      type: false,
      projectList: [],
      platformList: [
        { label: 'IOS', value: 'ios' },
        { label: 'Android', value: 'android' }
      ],
      mediaList: [
        { label: 'Facebook', value: 'Facebook Ads' },
        { label: 'Tiktok', value: 'bytedanceglobal_int'},
        { label: 'Unity', value: 'unityads_int'}
      ],
      projectName : [],
      platform : '',
      media : [],
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1
    };
  },
  created() {
    /* this.getData(); */
    //this.getTable();
    this.getXM();
    this.PageCount();
  },
  methods: {
    handleClose(index, row) {
      // console.log(row.event_list);
      row.event_list.splice(index, 1);
      this.campaignUpdate(row.campaign_name, row.event_list, 'delete');
      // this.QQ.gameName.splice(this.QQ.gameName.indexOf(tag), 1);
    },
    //返回按钮
    fanhuiss() {
      this.pictLoading = false;
      this.QQ.gameName = '';
      this.QQ.phone = 'ios';
      this.tableData = [];
      this.fanhui = false;
      this.loadingT = false;
    },
    //添加全选功能
    selectAll1() {
      this.QQ.gameName = this.list1;
      // this.QQ.platform = this.list3;
    },
    qinchu1() {
      this.QQ.gameName = [];
      // this.QQ.platform = [];
      this.checked1 = false;
    },
    selectAll2() {
      this.QQ.phone = [];
      if (this.checked2) {
        this.QQ.phone = ['ios', 'android'];
      } else this.QQ.phone = [];
    },

    // 触发搜索按钮
    handleSearch() {
      this.show_info = true;
      this.show_campaign = false;
      this.searchLoading = true;
      this.pictLoading = true;
      this.fanhui = true;
      

      //post请求获取搜索内容
      this.$axios
        .post(
          'uac/info/',
          {
            project: this.projectName,
            date_range: this.date,
            platform:this.platform,
            // media:this.QQ.media,
            group_by: this.checkList
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.searchLoading = false;
            // 返回的list是[{},{},{},{}]结构
            this.tableData = res.data.list;
            this.pageInfo.total = res.data.list.length;
            this.tableColumns = [];
            for (var pro in res.data.list[0]) {
              this.tableColumns.push(pro);
            }
            this.tableColumns1 = this.tableColumns.splice(0, res.data.fix_columns);
            this.tableColumnsd = this.tableColumns.splice(0, res.data.fix_columns);
            this.tableColumns2 = this.tableColumns;
            this.loadingT = false;
            this.pictLoading = false;
            this.$nextTick(() => {
              this.$refs.multipleTable.doLayout();
              // el-table加ref="multipleTable"
            });
            this.$refs.searchInput.searchTable();
          }
        })
        .catch((e) => {
          this.searchLoading = false;
          this.$message({
              message: '搜索失败',
              type: 'warning',
              duration: 1500
            });
        });
    },
    handleSizeChange(val) {
      this.pageInfo.current = 1;
    },
    // 检索
    SearchInput(tableList){
      this.tableSearchList = tableList;
      this.pageInfo.total = tableList.length;
    },
    //导出csv文件
    download() {
      //datas：数据，fields：字段名
      try {
        var c = this.tableData;
        c.filter((item) => {
          delete item._XID;
        });
        const result = json2csv.parse(c);
        // 判断浏览器类型
        if (
          (navigator.userAgent.indexOf('compatible') > -1 && navigator.userAgent.indexOf('MSIE') > -1) ||
          navigator.userAgent.indexOf('Edge') > -1
        ) {
          //IE10或Edge浏览器
          var BOM = '\uFEFF';
          var csvData = new Blob([BOM + result], { type: 'text/csv' });
          navigator.msSaveBlob(csvData, `test.csv`);
        } else {
          //非IE浏览器
          var csvContent = 'data:text/csv;charset=utf-8,\uFEFF' + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement('a');
          link.href = encodeURI(csvContent);
          link.download = `data.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    selectXM(val) {
      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === this.list1) {
        this.checked1 = true;
      } else this.checked1 = false;
    },
    selectPT(val) {
      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === ['ios', 'android']) {
        this.checked2 = true;
      } else this.checked2 = false;
    },
    //获取游戏名
    getXM() {
      this.$http
        .get('uac/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.projectList = res.data.project_data;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    // 检查campaign是否存在eventList
    checkCampaign() {
      this.show_campaign = true;
      this.$http
        .post('uac/check_campaign/',
          {
            project: this.projectName,
            date_range: this.date,
            platform:this.platform
          }, {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 201) {
            this.null_campaign = res.data.list;
            // console.log(this.null_campaign)
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    // 插入Campaign事件
    handleInsert(row) {
      if (row.inputValue.length == 0) {
        // 
      } else {
        row.event_list.push(row.inputValue);
        row.inputValue = '';
        this.campaignUpdate(row.campaign_name, row.event_list, 'insert');
        // console.log(value);
      }
    },
    // 更新campaign的eventList 信息
    campaignUpdate(campaignName,eventInfo,type) {
      this.$http
        .post('uac/campaign_update/',
          {
            campaign_name: campaignName,
            event_list: eventInfo,
            type: type
          }, {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.$message({
              message: '更新成功',
              type: 'success',
              duration: 1500
            });
            // this.null_campaign = res.data.list;
            // console.log(this.null_campaign)
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    // 访问
    PageCount() {
      this.$http
        .post('index/count/', 
        {
          page_name:"UAC2.5事件分析"
        },
        {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            // this.$refs.childProject.project_list = res.data.project_data;
            // this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log('Auth Error');
        if (localStorage.getItem("jwt-token") != null){
          localStorage.clear();
          window.location.href="/#/login";
        } 
        });
    },
    // 获取之前的日期的函数
    getBeforeDate(n) {
          let _n = n;
          let _d = new Date();
          let _year = _d.getFullYear();
          let _mon = _d.getMonth() + 1;
          let _day = _d.getDate();
          if(_day <= _n) {
              if(_mon > 1) {
                  _mon = _mon - 1;
              } else {
                  _year = _year - 1;
                  _mon = 12;
              }
          }
          _d.setDate(_d.getDate() - n);
          _year = _d.getFullYear();
          _mon = _d.getMonth() + 1;
          _day = _d.getDate();
          var date_sub = _year + "-" + (_mon < 10 ? ('0' + _mon) : _mon) + "-" + (_day < 10 ? ('0' + _day) : _day);
          return date_sub;
      },
  },
  mounted() {
    let timeStamp = new Date();
    let year = new Date(timeStamp).getFullYear();
    let month = new Date(timeStamp).getMonth() + 1 < 10 ? '0' + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1;
    let month1 = new Date(timeStamp).getMonth() < 10 ? '0' + new Date(timeStamp).getMonth() : new Date(timeStamp).getMonth();
    let date = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate();
    let date_sub = this.getBeforeDate(30);
    this.date = [date_sub, year + '-' + month + '-' + date];
  },
  watch: {
    inputXM() {
      var newlist1 = [];
      this.list1.filter((item) => {
        if (item.includes(this.inputXM)) {
          newlist1.push(item);
        }
      });
      this.list1 = newlist1;
      if (this.inputXM == '') {
        this.getXM();
      }
    }
  }
};
</script>
<style>
.el-tag .el-icon-close {
  float: right;
  margin-top: 4px;
}
.el-tag {
  background: white;
  width: 100%;
}
.elx-header--column.col--ellipsis > .elx-cell .elx-cell--title {
  overflow: initial;
  text-overflow: ellipsis;
  white-space: pre-line;
}
.elx-table {
  font-size: 12px;
}
.elx-table .elx-header--column {
  position: relative;
  line-height: 1;
  text-align: left;
}
.el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.el-tag.el-tag--info .el-tag__close {
  display: none;
}
.el-tag.el-tag--info {
  border: none;
  background: white;
}
.el-select__tags-text {
  margin-left: 0px;
}
.el-date-editor--daterange.el-input__inner {
  width: 240px;
}
.el-picker-panel {
  margin-left: 240px;
}
</style>
<style scoped>
.table {
  width: 100%;
  font-size: 14px;
  margin-top: 20px;
}
</style>
