<template>
  <div>
    <div v-if="user_group.indexOf('测试组') == -1 && user_group.indexOf('市场组') == -1">
      <cw403></cw403>
    </div>
    <div v-if="user_group.indexOf('测试组') > -1 || user_group.indexOf('市场组') > -1">
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            买量数据
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container">
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">筛选维度</span>
          <date-picker
            style="margin-right: 10px"
            v-model="date"
          >
          </date-picker>
            <el-select v-model="Project" @change="ProjectChange($event)" filterable placeholder="项目" style="margin-right: 10px;">
                <el-option
                v-for="item in ProjectList"
                :key="item"
                :label="item"
                :value="item">             
                </el-option>
            </el-select>
            <el-select v-model="Platform" @change="PlatformChange($event)" filterable clearable placeholder="平台" style="margin-right: 10px;">
                    <el-option
                    v-for="item in PlatformList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
            </el-select>
            <el-select v-model="MediaSource" filterable multiple collapse-tags placeholder="媒体渠道" style="margin-right: 10px;">
                    <el-option
                    v-for="item in MediaSourceList"
                    :key="item"
                    :label="item"
                    :value="item">
                    </el-option>
            </el-select>

        </div>
        <div class="options-bar" style="margin-left: 95px">
            <el-select v-model="Dimension" filterable clearable placeholder="ID维度分组" style="margin-right: 10px;width:240px">
                <el-option
                v-for="item in DimensionList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            <el-button
            type="primary"
            plain
            icon="el-icon-search"
            @click="handleSearch"
            style="margin-bottom: 10px; margin-top: 10px; margin-right: 10px"
            :disabled="kaiguan"
            >搜索</el-button
          >
          <el-button v-if="fanhui" plain type="primary" icon="el-icon-search" @click="fanhuiss" style="margin-left: 0px">返回</el-button>
        </div>
        <div class="options-bar">
          <span style="16px;color:gray;margin-right:30px;float:left;">快速检索</span>
          <ElSearchInput ref="searchInput" @SearchInput='SearchInput($event)'></ElSearchInput>
        </div>
          <el-button
            @click="download"
            style="float: right;"
            icon="el-icon-s-release"
            type="info"
            plain
            >导出csv</el-button>
        <el-dropdown style="float: right; margin-right: 5px; padding-bottom:5px">
          <el-button type="primary" plain>
            列筛选<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown" style="max-height: 400px;overflow: scroll;">
              <el-checkbox-group style="padding:10px 0px" @change="changeTableColumns" v-model="tableColumnsCheckedShow">
                <el-checkbox style="display:block;padding:5px 0px 5px 10px" :key="item" :label="item" v-for="item in tableColumnsCheckedList"></el-checkbox>
              </el-checkbox-group>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button type="primary" style="float: right; margin-right: 5px;" @click="clearSelectColumns" plain>清空列筛选</el-button>

        <el-table
          v-loading="pictLoading"
          element-loading-text="数据正在加载中"
          :data="tableSearchList"
          height="550"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          :header-cell-style="{ color: '#696969' }"
        >
          <ex-table-column
            :min-width="100"
            :autoFit="true"
            :fitHeader="true"
            sortable
            v-for="item in tableColumns1"
            :key="item"
            :prop="item"
            fixed
            :label="item"
          />

          <ex-table-column
            :autoFit="true"
            :fitHeader="true"
            sortable
            v-for="item in tableColumns2"
            :key="item"
            :prop="item"
            :label="item"
          />
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          :current-page.sync="pageInfo.current"
          :page-size.sync="pageInfo.size"
          :total="pageInfo.total"
        ></pagination>
      </div>

      <el-dialog title="市场消耗模块" :visible.sync="dialogVisible" width="30%">
        <div style="margin-top: -20px">
          <h2>分组维度/筛选条件</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>项目</li>
            <li>渠道</li>
            <li>媒体渠道</li>
            <li>日期范围</li>
            <li>国家</li>
            <li>账号</li>
            <li>Campaign名称</li>
          </ul>
          <h2 style="margin-top: 20px">数据指标</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>消耗</li>
            <li>安装</li>
            <li>CPI</li>
            <li>CPM</li>
            <li>展示数</li>
            <li>点击</li>
            <li>点击率</li>
            <li>展示</li>
          </ul>
          <h2 style="margin-top: 20px">说明</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>搜索条件对大小写不敏感，us US Us 均可搜索到US地区的数据</li>
            <li>快速检索：输入字段进行检索，支持多字段检索（以空格分隔输入，例：2020-10-01 火柴人神射手 ios）</li>
          </ul>
        </div>

        <!-- <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span> -->
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import echarts from 'echarts';
import { fetchData } from '../../api/index';
import json2csv from 'json2csv';
import cw403 from './403.vue';
import ElSearchInput from '../SelectComponent/searchInput.vue';
export default {
  name: 'AdCash-AllReport',
  components: {
    cw403,
    ElSearchInput
  },
  data() {
    return {
        Dimension:'',
        DimensionList:[
            { label: '计划ID', value: 'campaign_id' },
            { label: '广告组ID', value: 'ad_set_id' },
            { label: '广告ID', value: 'ad_id' },
        ],
        MediaSource:[],
        MediaSourceList:[],
        Platform:'android',
        PlatformList: [
            { label: 'Android', value: 'android' },
            { label: 'iOS', value: 'ios' }
        ],
        ProjectList:[],
        Project:'',
        tableColumnsCheckedShow: [],
        tableColumnsCheckedList: [],
        kaiguan: false,
        user_group: localStorage.getItem('user-group'),
        tableColumns: [],
        tableColumns1: [],
        tableColumns2: [],
        //折线图参数
        time: [],
        active1: [],
        active2: [],
        addmoney1: [],
        addmoney2: [],
        tableSearchList: [],
        fanhui: false,
        pictLoading: false,
        sjLoading: false,
        pageInfo: {
            size: 50,
            current: 1,
            total: 0,
        },
        date: [],
        dialogVisible: false,
        type: false,
        tableData: [],
        lastquery: '',
        multipleSelection: [],
        delList: [],
        editVisible: false,
        pageTotal: 0,
        form: {},
        idx: -1,
        id: -1,
    };
  },
  created() {
    // 初始请求，请求项目
    this.getProject();
    this.PageCount();
    // this.getAdsource();
    // this.getCountry();
  },
  methods: {
    ProjectChange(e){
        this.getMediasource();
    },
    PlatformChange(e){
        this.getMediasource();
    },
    AdsourceChange(e){

    },
    CountryChange(e){
      // 当改变值的时候，请求下个选项框的值，如果此选择框没有联动的下个选项框就为空就可以
      // console.log('接受');
      // console.log(e);
    },
    AdFormatChange(e){
      // 
    },
    //checkbox-group回调
    changeTableColumns(val) {
        // 保存已筛选的列
      this.tableColumnsCheckedShow = val;
      // 控制表格显示列  
      this.tableColumns2 = val;
    },
    clearSelectColumns() {
        this.tableColumnsCheckedShow = [];
        this.tableColumns2 = this.tableColumns
    },
    //返回按钮
    fanhuiss() {
      this.fanhui = false;
      this.tableData = [];
    },  
    // 触发搜索按钮
    handleSearch() {
      this.pictLoading = true;
      this.fanhui = true;
      //post请求获取搜索内容
      this.$axios
        .post(
          'mk_campaign/info/',
          {
            project: [this.Project],
            date_range: this.date,
            media_data: this.MediaSource,
            platform:this.Platform,
            dimension:this.Dimension,
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.tableData = res.data.list;
            this.pageInfo.total = res.data.list.length;
            this.tableColumns = [];
            for (var pro in res.data.list[0]) {
              this.tableColumns.push(pro);
            }
            this.tableColumns1 = this.tableColumns.splice(0, res.data.fix_columns);
            // this.tableColumns2 = this.tableColumns;
            // 筛选的列 搜索时，保留上次筛选的值
            this.tableColumnsCheckedList = this.tableColumns;
            if (this.tableColumnsCheckedShow.length != 0){
                this.tableColumns2 = this.tableColumnsCheckedShow;
                }else {
                this.tableColumns2 = this.tableColumns;
            }
            // this.tableColumnsCheckedShow = []; // 再次搜索时，将已选的全部重置
            this.$nextTick(() => {
              this.$refs.multipleTable.doLayout();
              // el-table加ref="multipleTable"
            });
            this.pictLoading = false;
            // 搜索需要经过子组件里的检索函数
            this.$refs.searchInput.searchTable();
          }
        })
        .catch((e) => {});
    },
    handleSizeChange() {
      this.pageInfo.current = 1;
    },
    // 检索
    SearchInput(tableList){
      this.tableSearchList = tableList;
      this.pageInfo.total = tableList.length;
    },
    //导出csv文件
    download() {
      //datas：数据，fields：字段名
      try {
        const result = json2csv.parse(this.tableData);
        // 判断浏览器类型
        if (
          (navigator.userAgent.indexOf('compatible') > -1 && navigator.userAgent.indexOf('MSIE') > -1) ||
          navigator.userAgent.indexOf('Edge') > -1
        ) {
          //IE10或Edge浏览器
          var BOM = '\uFEFF';
          var csvData = new Blob([BOM + result], { type: 'text/csv' });
          navigator.msSaveBlob(csvData, `test.csv`);
        } else {
          //非IE浏览器
          var csvContent = 'data:text/csv;charset=utf-8,\uFEFF' + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement('a');
          link.href = encodeURI(csvContent);
          link.download = `test.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    //获取游戏名
    getProject() {
        this.$http
            .get('mk_campaign/project_data/', {
            headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
            })
            .then(function (res) {
            if (res.data.code == 200) {
                this.ProjectList = res.data.project_data;
                // this.list1.unshift('all');
            }
            })
            .catch(function (err) {
                console.log(err);
            });
    },
    // 获取广告平台的信息
    getMediasource(){
        this.$axios
          .post(
            'mk_campaign/media_data/',
            {
              date_range: this.date,
              project: [this.Project],
              platform:this.Platform, 
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.MediaSourceList = res.data.media_data;
          })
        .catch((e) => {});
    },
    // 访问
    PageCount() {
      this.$http
        .post('index/count/', 
        {
          page_name: "买量数据"
        },
        {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            // this.$refs.childProject.project_list = res.data.project_data;
            // this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log('Auth Error');
        if (localStorage.getItem("jwt-token") != null){
          localStorage.clear();
          window.location.href="/#/login";
        } 
        });
    },
    // 获取之前的日期的函数
    getBeforeDate(n) {
          let _n = n;
          let _d = new Date();
          let _year = _d.getFullYear();
          let _mon = _d.getMonth() + 1;
          let _day = _d.getDate();
          if(_day <= _n) {
              if(_mon > 1) {
                  _mon = _mon - 1;
              } else {
                  _year = _year - 1;
                  _mon = 12;
              }
          }
          _d.setDate(_d.getDate() - n);
          _year = _d.getFullYear();
          _mon = _d.getMonth() + 1;
          _day = _d.getDate();
          var date_sub = _year + "-" + (_mon < 10 ? ('0' + _mon) : _mon) + "-" + (_day < 10 ? ('0' + _day) : _day);
          return date_sub;
      },
  },

  mounted() {
    let timeStamp = new Date();
    let year = new Date(timeStamp).getFullYear();
    let month = new Date(timeStamp).getMonth() + 1 < 10 ? '0' + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1;
    let month1 = new Date(timeStamp).getMonth() < 10 ? '0' + new Date(timeStamp).getMonth() : new Date(timeStamp).getMonth();
    let date = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate();
    let date_sub = this.getBeforeDate(7);
    this.date = [date_sub, year + '-' + month + '-' + date];
  },

};
</script>
<style>
.el-tag .el-icon-close {
  float: right;
  margin-top: 4px;
}
.el-tag {
  background: white;
  width: 100%;
}
.el-table .cell,
.el-table th > .cell {
  white-space: nowrap;
  width: fit-content;
}
.el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.el-tag.el-tag--info .el-tag__close {
  display: none;
}
.el-tag.el-tag--info {
  border: none;
  background: white;
}
.el-select__tags-text {
  margin-left: 0px;
}
.el-date-editor--daterange.el-input__inner {
  width: 240px;
}
.el-picker-panel {
  margin-left: 240px;
}
</style>
<style scoped>
.table {
  width: 100%;
  font-size: 14px;
}
</style>