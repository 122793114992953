<template>
  <div>
    <div v-if="user_group.indexOf('测试组') == -1 && user_group.indexOf('市场组') == -1">
      <cw403></cw403>
    </div>
    <div v-if="user_group.indexOf('测试组') > -1 || user_group.indexOf('市场组') > -1">
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            产品LTV数据
            <el-button type="text" style="font-size: 16px" @click="dialogVisible = true">(页面说明)</el-button>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container">
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">筛选维度</span>
          <date-picker
            style="margin-right: 10px"
            v-model="date"
          >
          </date-picker>
          <!-- <el-select v-model="QQ.gameName" @change="selectXM" filterable placeholder="项目" collapse-tags style="margin-right: 10px; width: 180px">
            <el-option v-for="item in list1" :key="item" :label="item" :value="item"></el-option>
          </el-select> -->
          <!-- 项目筛选 -->
          <el-select
            v-model="QQ.gameName"
            @change="selectXM"
            multiple
            placeholder="项目"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputXM"
                @keyup.enter.native="enterXM"
                clearable
              ></el-input>
              <button @click="selectAll1" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 20px" @click="qinchu1">清空</button>
              <el-option v-for="item in list1" :key="item" :label="item" :value="item"></el-option>
              <el-option v-if="list1 == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 150px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in QQ.gameName" :key="item">
                <el-tag closable @close="handleClose(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
          <!-- 平台筛选 -->
          <el-select v-model="QQ.phone" @change="selectPT" placeholder="平台" collapse-tags style="margin-right: 10px; width: 180px">
            <el-option v-for="item in list2" :key="item.vaue" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <!-- 渠道篩選 -->
          <el-select v-model="MediaSource" filterable multiple collapse-tags placeholder="媒体渠道" style="margin-right: 10px;">
                    <el-option
                    v-for="item in MediaSourceList"
                    :key="item"
                    :label="item"
                    :value="item">
                    </el-option>
          </el-select>
          <!-- 地区筛选 -->
          <el-select
            v-model="QQ.country"
            @change="selectGZ"
            multiple
            placeholder="所有国家/地区"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputGZ"
                @keyup.enter.native="enterGZ"
                clearable
              ></el-input>
              <button @click="selectAll3" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 20px" @click="qinchu3">清空</button>
              <el-option v-for="item in list3" :key="item" :label="item" :value="item"> </el-option>
              <el-option v-if="list3 == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 160px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in QQ.country" :key="item">
                <el-tag closable @close="handleCloseGZ(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
          <!-- <el-select v-model="QQ.platform" @change="selectPT" placeholder="默认分渠道看数据" collapse-tags style="margin-right: 10px; width: 180px">
            <el-option v-for="item in list3" :key="item.vaue" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-select v-model="splitDate" placeholder="默认分天(批次)看数据" collapse-tags style="margin-right: 10px; width: 180px">
            <el-option v-for="item in list4" :key="item.vaue" :label="item.label" :value="item.value"> </el-option>
          </el-select> -->
          <!-- 搜索按钮 -->
          <el-button type="primary" plain icon="el-icon-search" @click="handleSearch" :loading="loadingT"
            >搜索</el-button
          >
          <el-button v-if="fanhui" type="primary" plain icon="el-icon-search" @click="fanhuiss">清空筛选</el-button>
        </div>
        <div class="options-bar">
          <span style="16px;color:gray;margin-right:30px;float:left;">快速检索</span>
          <ElSearchInput ref="searchInput" @SearchInput='SearchInput($event)'></ElSearchInput>
        </div>

          <el-button
            @click="download"
            style="float: right;"
            icon="el-icon-s-release"
            type="info"
            plain
            >导出csv</el-button>
        <el-dropdown style="float: right; margin-right: 5px; padding-bottom:5px">
          <el-button type="primary" plain>
            列筛选<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown" style="max-height: 400px;overflow: scroll;">
              <el-checkbox-group style="padding:10px 0px" @change="changeTableColumns" v-model="tableColumnsCheckedShow">
                <el-checkbox style="display:block;padding:5px 0px 5px 10px" :key="item" :label="item" v-for="item in tableColumnsCheckedList"></el-checkbox>
              </el-checkbox-group>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button type="primary" style="float: right; margin-right: 5px;" @click="clearSelectColumns" plain>清空列筛选</el-button>

        <el-table
          v-loading="pictLoading"
          element-loading-text="数据正在加载中"
          :data="tableSearchList.slice((pageInfo.current-1)*pageInfo.size,pageInfo.current*pageInfo.size)"
          height="550"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          :header-cell-style="{ color: '#696969' }"
        >
          <ex-table-column
            width="100"
            :autoFit="true"
            :fitHeader="true"
            sortable
            v-for="item in tableColumns1"
            :key="item"
            :prop="item"
            fixed
            :label="item"
          />
          <ex-table-column
            width="300"
            :autoFit="true"
            :fitHeader="true"
            sortable
            v-for="item in tableColumnsd"
            :key="item"
            :prop="item"
            fixed
            :label="item"
          />

          <ex-table-column
            width="100"
            :autoFit="true"
            :fitHeader="true"
            sortable
            v-for="item in tableColumns2"
            :key="item"
            :prop="item"
            :label="item"
          />
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          :current-page.sync="pageInfo.current"
          :page-size.sync="pageInfo.size"
          :total="pageInfo.total"
        ></pagination>
      </div>

      <el-dialog title="计划群组模块" :visible.sync="dialogVisible" width="30%">
        <div style="margin-top: -20px">
          <h2>WaitUpdateLater</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>
              <h3>筛选条件</h3>
              <ul>
                <li>项目</li>
                <li>渠道</li>
                <li>日期范围</li>
              </ul>
            </li>
          </ul>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>
              <h3>数据指标</h3>
              <ul>
                <li>基础数据部分</li>
                <li>留存部分</li>
                <li>广告部分</li>
                <li>内购部分</li>
              </ul>
            </li>
          </ul>
          <h2 style="margin-top: 20px">说明</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>所有筛选项都是不强制全选，比如：可选日期范围、项目，不选平台，点击搜索</li>
            <li>快速检索：输入字段进行检索，支持多字段检索（以空格分隔输入，例：2020-10-01 火柴人神射手 ios）</li>
          </ul>
        </div>

        <!-- <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span> -->
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { fetchData } from '../../api/index';
import json2csv from 'json2csv';
import cw403 from './403.vue';
import ElSearchInput from '../SelectComponent/searchInput.vue';
import { P } from 'af-table-column';
export default {
  name: 'product-table3',
  components: {
    cw403,
    ElSearchInput
  },
  data() {
    return {
      tableColumnsCheckedShow: [],
      tableColumnsCheckedList: [],
      inputXM: '',
      user_group: localStorage.getItem('user-group'),
      loadingT: false,
      tableSearchList: [],
      tableColumns: [],
      tableColumns1: [],
      tableColumns2: [],
      MediaSource:[],
      MediaSourceList:[],
      selectGZ: '',
      countryList:['all','AF', 'AX', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BQ', 'BA', 'BW', 'BV', 'BR', 'IO', 'BN', 'BG', 'BF', 'BI', 'CV', 'KH', 'CM', 'CA', 'KY', 'CF', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CG', 'CD', 'CK', 'CR', 'CI', 'HR', 'CU', 'CW', 'CY', 'CZ', 'DK', 'DJ', 'DM', 'DO', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'SZ', 'ET', 'FK', 'FO', 'FJ', 'FI', 'FR', 'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GU', 'GT', 'GG', 'GN', 'GW', 'GY', 'HT', 'HM', 'VA', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IM', 'IL', 'IT', 'JM', 'JP', 'JE', 'JO', 'KZ', 'KE', 'KI', 'KP', 'KR', 'KW', 'KG', 'LA', 'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MS', 'MA', 'MZ', 'MM', 'NA', 'NR', 'NP', 'NL', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU', 'NF', 'MK', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA', 'RE', 'RO', 'RU', 'RW', 'BL', 'SH', 'KN', 'LC', 'MF', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS', 'SC', 'SL', 'SG', 'SX', 'SK', 'SI', 'SB', 'SO', 'ZA', 'GS', 'SS', 'ES', 'LK', 'SD', 'SR', 'SJ', 'SE', 'CH', 'SY', 'TW', 'TJ', 'TZ', 'TH', 'TL', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE', 'GB', 'US', 'UM', 'UY', 'UZ', 'VU', 'VE', 'VN', 'VG', 'VI', 'WF', 'EH', 'YE', 'ZM', 'ZW'],
      list3: ['all','AF', 'AX', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BQ', 'BA', 'BW', 'BV', 'BR', 'IO', 'BN', 'BG', 'BF', 'BI', 'CV', 'KH', 'CM', 'CA', 'KY', 'CF', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CG', 'CD', 'CK', 'CR', 'CI', 'HR', 'CU', 'CW', 'CY', 'CZ', 'DK', 'DJ', 'DM', 'DO', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'SZ', 'ET', 'FK', 'FO', 'FJ', 'FI', 'FR', 'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GU', 'GT', 'GG', 'GN', 'GW', 'GY', 'HT', 'HM', 'VA', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IM', 'IL', 'IT', 'JM', 'JP', 'JE', 'JO', 'KZ', 'KE', 'KI', 'KP', 'KR', 'KW', 'KG', 'LA', 'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MS', 'MA', 'MZ', 'MM', 'NA', 'NR', 'NP', 'NL', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU', 'NF', 'MK', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA', 'RE', 'RO', 'RU', 'RW', 'BL', 'SH', 'KN', 'LC', 'MF', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS', 'SC', 'SL', 'SG', 'SX', 'SK', 'SI', 'SB', 'SO', 'ZA', 'GS', 'SS', 'ES', 'LK', 'SD', 'SR', 'SJ', 'SE', 'CH', 'SY', 'TW', 'TJ', 'TZ', 'TH', 'TL', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE', 'GB', 'US', 'UM', 'UY', 'UZ', 'VU', 'VE', 'VN', 'VG', 'VI', 'WF', 'EH', 'YE', 'ZM', 'ZW'],
      fanhui: false,
      checked1: false,
      checked2: false,
      checked3: false,
      pictLoading: false,
      pageInfo: {
        size: 50,
        current: 1,
        total: 0,
      },
      date: [],

      dialogVisible: false,
      type: false,
      projectList: [],
      list1: [],
      list2: [
        { label: 'IOS', value: 'ios' },
        { label: 'Android', value: 'android' }
      ],
      // list3: [
      //   { label: '分渠道看数据', value: 'True' },
      //   { label: '分计划看数据', value: 'False' }
      // ],
      list4: [
        { label: '分日期看数据', value: 'True' },
        { label: '去掉日期看数据', value: 'False' }
      ],
      splitDate : 'True',
      QQ: {
        date: [],
        gameName: [],
        phone: 'android',
        country: []
      },
      inputGZ: '',
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1
    };
  },
  created() {
    /* this.getData(); */
    //this.getTable();
    this.getXM();
    this.PageCount();
  },
  methods: {
    // 列筛选
    changeTableColumns(val) {
      this.tableColumnsCheckedShow = val;
      this.tableColumns2 = val;
    },
    // 清空列筛选
    clearSelectColumns() {
        this.tableColumnsCheckedShow = [];
        this.tableColumns2 = this.tableColumns
    },
    handleClose(tag) {
      this.QQ.gameName.splice(this.QQ.gameName.indexOf(tag), 1);
    },
    handleCloseGZ(tag) {
      this.QQ.country.splice(this.QQ.country.indexOf(tag), 1);
    },
    //返回按钮
    fanhuiss() {
      this.pictLoading = false;
      this.QQ.gameName = '';
      this.QQ.phone = '';
      this.tableData = [];
      this.fanhui = false;
      this.loadingT = false;
    },
    //添加全选功能
    selectAll1() {
      this.QQ.gameName = this.list1;
      // this.QQ.platform = this.list3;
    },
    selectAll3() {
      this.QQ.country = this.list3;
    },
    qinchu1() {
      this.QQ.gameName = [];
      // this.QQ.platform = [];
      this.checked1 = false;
    },
    qinchu3() {
      this.QQ.country = [];
      this.checked3 = false;
    },
    selectAll2() {
      this.QQ.phone = [];
      if (this.checked2) {
        this.QQ.phone = ['ios', 'android'];
      } else this.QQ.phone = [];
    },

    // 触发搜索按钮
    handleSearch() {
      this.loadingT = true;
      this.pictLoading = true;
      this.fanhui = true;
      var a = this.date;
      var b = [];
      var c = [];
      var d = [];
      if (this.QQ.gameName == '') b = '';
      else b = this.QQ.gameName;
      if (this.QQ.phone == '') c = 'android';
      else c = this.QQ.phone;


      //post请求获取搜索内容
      this.$axios
        .post(
          'ltv_info/info/',
          {
            project: b,
            platform: c,
            date_range: a,
            media: this.MediaSource,
            country:this.QQ.country
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          if (res.data.code == 200) {
            // 返回的list是[{},{},{},{}]结构
            this.tableData = res.data.list;
            this.pageInfo.total = res.data.list.length;
            this.tableColumns = [];
            for (var pro in res.data.list[0]) {
              this.tableColumns.push(pro);
            }
            this.tableColumns1 = this.tableColumns.splice(0, res.data.fix_columns);
            this.tableColumnsd = this.tableColumns.splice(0, res.data.fix_columns);
            // this.tableColumns2 = this.tableColumns;
            // 筛选的列 搜索时，保留上次筛选的值，tableColumns2为控制列表展示的
            this.tableColumnsCheckedList = this.tableColumns;
            if (this.tableColumnsCheckedShow.length != 0){
                this.tableColumns2 = this.tableColumnsCheckedShow;
                }else {
                this.tableColumns2 = this.tableColumns;
            }
            this.loadingT = false;
            /* setTimeout(() => {
                                this.tableColumns3=this.tableColumns.splice(200,450)
                                this.$nextTick(() => {
                                this.$refs.multipleTable.doLayout();
                                
                            });
                            }, 4000); */
            this.pictLoading = false;
            this.$nextTick(() => {
              this.$refs.multipleTable.doLayout();
              // el-table加ref="multipleTable"
            });
            this.$refs.searchInput.searchTable();
          }
          else
          {
            this.loadingT = false;
            /* setTimeout(() => {
                                this.tableColumns3=this.tableColumns.splice(200,450)
                                this.$nextTick(() => {
                                this.$refs.multipleTable.doLayout();
                                
                            });
                            }, 4000); */
            this.pictLoading = false;
          }
        })
        .catch((e) => {
          this.loadingT = false;
            /* setTimeout(() => {
                                this.tableColumns3=this.tableColumns.splice(200,450)
                                this.$nextTick(() => {
                                this.$refs.multipleTable.doLayout();
                                
                            });
                            }, 4000); */
          this.pictLoading = false;
        });
    },
    handleSizeChange(val) {
      this.pageInfo.current = 1;
    },
    // 检索
    SearchInput(tableList){
      this.tableSearchList = tableList;
      this.pageInfo.total = tableList.length;
    },
    //导出csv文件
    download() {
      //datas：数据，fields：字段名
      try {
        var c = this.tableData;
        c.filter((item) => {
          delete item._XID;
        });
        const result = json2csv.parse(c);
        // 判断浏览器类型
        if (
          (navigator.userAgent.indexOf('compatible') > -1 && navigator.userAgent.indexOf('MSIE') > -1) ||
          navigator.userAgent.indexOf('Edge') > -1
        ) {
          //IE10或Edge浏览器
          var BOM = '\uFEFF';
          var csvData = new Blob([BOM + result], { type: 'text/csv' });
          navigator.msSaveBlob(csvData, `test.csv`);
        } else {
          //非IE浏览器
          var csvContent = 'data:text/csv;charset=utf-8,\uFEFF' + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement('a');
          link.href = encodeURI(csvContent);
          link.download = `data.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    selectXM(val) {
      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === this.list1) {
        this.checked1 = true;
      } else 
      {
        this.checked1 = false;
        this.getMediasource();
      }
    },
    selectGZ(val) {
      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === this.list3) {
        this.checked3 = true;
      } else 
      {
        this.checked3 = false;
        this.getMediasource();
      }

    },
    selectPT(val) {
      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === ['ios', 'android']) {
        this.checked2 = true;
      } else
      {
        this.checked2 = false;
        this.getMediasource();
      } 
    },
    //获取游戏名
    getXM() {
      this.$http
        .get('campaign_group/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.list1 = res.data.project_data;
            this.projectList = res.data.project_data;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    // 获取广告平台的信息
    getMediasource(){
      var a = this.date;
      var b = [];
      var c = [];
      var d = [];
      if (this.QQ.gameName == '') b = '';
      else b = this.QQ.gameName;
      if (this.QQ.phone == '') c = 'Android';
      else c = this.QQ.phone;
      if (this.QQ.platform == '') d = [];
      else d = [this.QQ.platform];

      this.$axios
        .post(
          'mk_campaign/media_data/',
          {
            date_range: a,
            project: b,
            platform:c, 
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.MediaSourceList = res.data.media_data;
        })
      .catch((e) => {});
    },
    // 访问
    PageCount() {
      this.$http
        .post('index/count/', 
        {
          page_name:"买量回本数据-安卓"
        },
        {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            // this.$refs.childProject.project_list = res.data.project_data;
            // this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log('Auth Error');
        if (localStorage.getItem("jwt-token") != null){
          localStorage.clear();
          window.location.href="/#/login";
        } 
        });
    },
    // 获取之前的日期的函数
    getBeforeDate(n) {
          let _n = n;
          let _d = new Date();
          let _year = _d.getFullYear();
          let _mon = _d.getMonth() + 1;
          let _day = _d.getDate();
          if(_day <= _n) {
              if(_mon > 1) {
                  _mon = _mon - 1;
              } else {
                  _year = _year - 1;
                  _mon = 12;
              }
          }
          _d.setDate(_d.getDate() - n);
          _year = _d.getFullYear();
          _mon = _d.getMonth() + 1;
          _day = _d.getDate();
          var date_sub = _year + "-" + (_mon < 10 ? ('0' + _mon) : _mon) + "-" + (_day < 10 ? ('0' + _day) : _day);
          return date_sub;
      },
  },
  mounted() {
    let timeStamp = new Date();
    let year = new Date(timeStamp).getFullYear();
    let month = new Date(timeStamp).getMonth() + 1 < 10 ? '0' + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1;
    let month1 = new Date(timeStamp).getMonth() < 10 ? '0' + new Date(timeStamp).getMonth() : new Date(timeStamp).getMonth();
    let date = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate();
    let date_sub = this.getBeforeDate(30);
    this.date = [date_sub, year + '-' + month + '-' + date];
  },
  watch: {
    inputXM() {
      if (this.inputXM == '') {
        // this.getXM();
        this.list1 = projectList;
      } else
      {
        var newlist1 = [];
        this.projectList.filter((item) => {
        if (item.toUpperCase().includes(this.inputXM.toUpperCase())) {
          newlist1.push(item);
        }
        });
        console.log(newlist1);
        this.list1 = newlist1;
      }


    },
    inputGZ() {
      var newlist3 = [];
      this.countryList.filter((item) => {
        if (item.toUpperCase().includes(this.inputGZ.toUpperCase())) {
          newlist3.push(item);
        }
      });
      this.list3 = newlist3;
      if (this.inputGZ == '') {
        this.list3 = countryList;
      }
    }
  }
};
</script>
<style>
.el-tag .el-icon-close {
  float: right;
  margin-top: 4px;
}
.el-tag {
  background: white;
  width: 100%;
}
.elx-header--column.col--ellipsis > .elx-cell .elx-cell--title {
  overflow: initial;
  text-overflow: ellipsis;
  white-space: pre-line;
}
.elx-table {
  font-size: 12px;
}
.elx-table .elx-header--column {
  position: relative;
  line-height: 1;
  text-align: left;
}
.el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.el-tag.el-tag--info .el-tag__close {
  display: none;
}
.el-tag.el-tag--info {
  border: none;
  background: white;
}
.el-select__tags-text {
  margin-left: 0px;
}
.el-date-editor--daterange.el-input__inner {
  width: 240px;
}
.el-picker-panel {
  margin-left: 240px;
}
</style>
<style scoped>
.table {
  width: 100%;
  font-size: 14px;
  margin-top: 20px;
}
</style>
