<template>
  <div>
    <div v-if="user_group.indexOf('测试组') == -1 && user_group.indexOf('ADMAX户组') == -1">
      <cw403></cw403>
    </div>
    <div v-if="user_group.indexOf('测试组') > -1 || user_group.indexOf('ADMAX户组') > -1">
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            ADMAX户消耗数据模块
            <el-button type="text" style="font-size: 16px" @click="dialogVisible = true">(页面说明)</el-button>
            <font style="margin-left: 20px; color: #409eff">展示顺序:&nbsp;&nbsp;&nbsp;{{ checkList }}</font>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container">
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">分组维度</span>
          <el-checkbox-group @change="demo" v-model="checkList" style="display: inline">
            <el-checkbox label="项目" disabled></el-checkbox>
            <el-checkbox label="日期"></el-checkbox>
            <el-checkbox label="平台"></el-checkbox>
            <el-checkbox label="国家"></el-checkbox>
            <el-checkbox label="媒体渠道"></el-checkbox>
            <el-checkbox label="投放账号"></el-checkbox>
            <el-checkbox label="计划名称"></el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">筛选维度</span>
          <date-picker
            style="margin-right: 10px"
            v-model="date"
          ></date-picker>
          <el-select
            v-model="QQ.gameName"
            @change="selectXM"
            multiple
            placeholder="项目"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputXM"
                @keyup.enter.native="enterXM"
                clearable
              ></el-input>
              <button @click="selectAll1" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 20px" @click="qinchu1">清空</button>
              <el-option v-for="item in list1" :key="item" :label="item" :value="item"></el-option>
              <el-option v-if="list1 == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 150px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in QQ.gameName" :key="item">
                <el-tag closable @close="handleClose(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
          <el-select
            v-model="QQ.phone"
            @change="selectPT"
            multiple
            placeholder="平台"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <el-option v-for="item in list2" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-select
            v-model="QQ.country"
            @change="selectGZ"
            multiple
            placeholder="所有国家/地区"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputGZ"
                @keyup.enter.native="enterGZ"
                clearable
              ></el-input>
              <button @click="selectAll3" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 20px" @click="qinchu3">清空</button>
              <el-option v-for="item in list3" :key="item" :label="item" :value="item"> </el-option>
              <el-option v-if="list3 == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 160px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in QQ.country" :key="item">
                <el-tag closable @close="handleCloseGZ(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
        </div>
        <div class="options-bar" style="margin-left: 95px">
          <el-select
            v-model="QQ2.mt"
            multiple
            placeholder="所有媒体渠道"
            collapse-tags
            style="margin-right: 10px; width: 240px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputMT"
                @keyup.enter.native="enterMT"
                clearable
              ></el-input>
              <button @click="selectAll5" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 30px" @click="qinchu5">清空</button>
              <el-option v-for="item in list5" :key="item" :label="item" :value="item"> </el-option>
              <el-option v-if="list5 == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 160px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in QQ2.mt" :key="item">
                <el-tag closable @close="handleCloseMT(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
          <el-select
            v-model="QQ2.tf"
            @change="selectTF"
            multiple
            placeholder="投放账号"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 300px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputTF"
                @keyup.enter.native="enterTF"
                clearable
              ></el-input>
              <button @click="selectAll6" style="height: 24px; width: 70px; margin-left: 50px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 60px" @click="qinchu6">清空</button>
              <el-option v-for="item in list6" :key="item" :label="item" :value="item"> </el-option>
              <el-option v-if="list6 == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; padding: 10px; width: 300px">
              <div style="height: 30px" v-for="item in QQ2.tf" :key="item">
                <el-tag closable @close="handleCloseTF(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
          <el-select v-model="QQ2.jh" multiple placeholder="计划名称" collapse-tags style="margin-right: 10px; width: 180px">
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 300px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputJH"
                @keyup.enter.native="enterJH"
                clearable
              ></el-input>
              <button @click="selectAll7" style="height: 24px; width: 70px; margin-left: 50px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 60px" @click="qinchu7">清空</button>
              <el-option v-for="item in list7" :key="item" :label="item" :value="item"> </el-option>
              <el-option v-if="list7 == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 300px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in QQ2.jh" :key="item">
                <el-tag closable @close="handleCloseJH(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
          <el-select v-model="renyuan" multiple placeholder="人员" collapse-tags style="margin-right: 10px; width: 180px">
            <el-checkbox v-model="checked9" @change="selectAll9" style="padding-left: 10px; height: 34px; line-height: 34px"
              >全选
            </el-checkbox>
            <el-button
              type="text"
              style="padding-left: 10px; height: 34px; line-hieght: 34px; color: #606266; font-size: 14px"
              @click="qinchu9"
              >清空</el-button
            >
            <el-option v-for="item in listr" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
          <el-button
            type="primary"
            plain
            icon="el-icon-search"
            @click="handleSearch"
            style="margin-right: 10px"
            >搜索</el-button
          >
          <el-button v-if="fanhui" plain type="primary" icon="el-icon-search" @click="fanhuiss" style="margin-left: 0px"
            >返回全部</el-button
          >
        </div>
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">快速检索</span>
          <ElSearchInput ref="searchInput" @SearchInput='SearchInput($event)'></ElSearchInput>
          <el-button
            @click="download"
            style="margin-left: auto"
            icon="el-icon-s-release"
            type="info"
            plain
          >导出csv</el-button>
        </div>
      </div>

      <el-table
        v-loading="pictLoading"
        element-loading-text="数据正在加载中"
        :data="tableSearchList.slice((pageInfo.current-1)*pageInfo.size,pageInfo.current*pageInfo.size)"
        :current-page.sync="pageInfo.current"
        height="550"
        border
        class="table"
        ref="multipleTable"
        :header-cell-style="{ color: '#696969' }"
      >
        <af-table-column
          :min-width="120"
          sortable
          v-for="item in tableColumns1"
          :key="item"
          :prop="item"
          fixed
          :label="item"
        />

        <af-table-column
          sortable
          v-for="item in tableColumns2"
          :key="item"
          :prop="item"
          :label="item"
        />
      </el-table>

      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="pageInfo.current"
        :page-size="pageInfo.size"
        layout="sizes, prev, pager, next, jumper,total"
        :total="pageInfo.total"
      ></el-pagination> -->

      <pagination
        @size-change="handleSizeChange"
        :current-page.sync="pageInfo.current"
        :page-size.sync="pageInfo.size"
        :total="pageInfo.total"
      ></pagination>

    </div>

    <el-dialog title="市场消耗模块" :visible.sync="dialogVisible" width="30%">
      <div style="margin-top: -20px">
        <h2>分组维度/筛选条件</h2>
        <ul style="margin-left: 40px; margin-top: 10px">
          <li>项目</li>
          <li>渠道</li>
          <li>媒体渠道</li>
          <li>日期范围</li>
          <li>国家</li>
          <li>账号</li>
          <li>Campaign名称</li>
        </ul>
        <h2 style="margin-top: 20px">数据指标</h2>
        <ul style="margin-left: 40px; margin-top: 10px">
          <li>消耗</li>
          <li>安装</li>
          <li>CPI</li>
          <li>CPM</li>
          <li>展示数</li>
          <li>点击</li>
          <li>点击率</li>
          <li>展示</li>
        </ul>
        <h2 style="margin-top: 20px">说明</h2>
        <ul style="margin-left: 40px; margin-top: 10px">
          <li>搜索条件对大小写不敏感，us US Us 均可搜索到US地区的数据 </li>
          <li>快速检索：输入字段进行检索，支持多字段检索（以空格分隔输入，例：2020-10-01 火柴人神射手 ios）</li>
        </ul>
      </div>

      <!-- <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          </span> -->
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import echarts from 'echarts';
import { fetchData } from '../../api/index';
import AFTableColumn from 'af-table-column'
Vue.use(AFTableColumn) 
import json2csv from 'json2csv';
import cw403 from './403.vue';
import ElSearchInput from '../SelectComponent/searchInput.vue';
export default {
  name: 'market-table1',
  components: {
    cw403,
    ElSearchInput
  },
  data() {
    return {
      inputXM: '',
      inputGZ: '',
      inputMT: '',
      inputTF: '',
      inputJH: '',
      listr: ['tt', 'vv', 'cc', 'Crystal', 'LM', 'DD'],
      tableSearchList: [],
      renyuan: [],
      user_group: localStorage.getItem('user-group'),
      checkList: ['项目', '日期', '平台'],
      tableColumns: [],
      tableColumns1: [],
      tableColumns2: [],
      //折线图参数
      time: [],
      active1: [],
      active2: [],
      addmoney1: [],
      addmoney2: [],
      fanhui: false,
      checked1: false,
      checked2: false,
      checked3: false,
      checked5: false,
      checked6: false,
      checked7: false,
      checked9: false,
      pictLoading: true,
      pageInfo: {
        size: 200,
        current: 1,
        total: 1000,
      },
      date: [],

      dialogVisible: false,
      type: false,
      list1: [],
      list2: [
        { label: '所有平台', value: 'all' },
        { label: 'IOS', value: 'ios' },
        { label: 'Android', value: 'android' }
      ],
      list3: [],
      list5: [],
      list6: [],
      list7: [],
      QQ: {
        date: [],
        gameName: [],
        phone: [],
        country: []
      },
      QQ2: {
        mt: [],
        tf: [],
        jh: []
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1
    };
  },
  created() {
    /* this.getData(); */
    this.getTable();
    this.getXM();
    this.getGZ();
    this.PageCount();
  },
  computed: {
      // 计算属性对数据进行处理
      frontEndPageChange() {
       let start = (this.pageInfo.current - 1) * this.pageInfo.size;
       if (start >= this.tableData.length) start = 0;
       let end = this.pageInfo.current * this.pageInfo.size;
       if (end >= this.tableData.length) end = this.tableData.length;
       return this.tableData.slice(start, end);
     }
    },

  methods: {
    //回车选中第一项方法
    enterXM() {
      if (this.QQ.gameName.includes(this.list1[0])) {
        console.log('已选中');
      } else this.QQ.gameName.push(this.list1[0]);
      this.getGZ();
      this.getMT();
      this.getJH();
      this.getTF();
    },
    enterGZ() {
      if (this.QQ.country.includes(this.list3[0])) {
        console.log('已选中');
      } else this.QQ.country.push(this.list3[0]);
      this.getMT();
      this.getJH();
      this.getTF();
    },
    enterMT() {
      if (this.QQ2.mt.includes(this.list5[0])) {
        console.log('已选中');
      } else this.QQ2.mt.push(this.list5[0]);
      this.getTF();
      this.getJH();
    },
    enterTF() {
      if (this.QQ2.tf.includes(this.list6[0])) {
        console.log('已选中');
      } else this.QQ2.tf.push(this.list6[0]);
      this.getJH();
    },
    enterJH() {
      if (this.QQ2.jh.includes(this.list7[0])) {
        console.log('已选中');
      } else this.QQ2.jh.push(this.list7[0]);
    },
    handleClose(tag) {
      this.QQ.gameName.splice(this.QQ.gameName.indexOf(tag), 1);
    },
    handleCloseGZ(tag) {
      this.QQ.country.splice(this.QQ.country.indexOf(tag), 1);
    },
    handleCloseMT(tag) {
      this.QQ2.mt.splice(this.QQ2.mt.indexOf(tag), 1);
    },
    handleCloseTF(tag) {
      this.QQ2.tf.splice(this.QQ2.tf.indexOf(tag), 1);
    },
    handleCloseJH(tag) {
      this.QQ2.jh.splice(this.QQ2.jh.indexOf(tag), 1);
    },
    //checkbox-group回调
    demo(val) {
      this.checkList = val;
    },
    //返回按钮
    fanhuiss() {
      (this.time = []), (this.addmoney1 = []), (this.addmoney2 = []), (this.active1 = []), (this.active2 = []), (this.pictLoading = true);
      this.QQ.gameName = '';
      this.QQ.phone = '';
      this.QQ.country = '';
      this.getTable();

      this.fanhui = false;
    },
    //添加全选功能
    selectAll1() {
      this.QQ.gameName = this.list1;

      this.getMT();
      this.getTF();
      this.getJH();
    },
    qinchu1() {
      this.QQ.gameName = [];
      this.checked1 = false;
    },
    selectAll2() {
      this.QQ.phone = [];
      if (this.checked2) {
        this.QQ.phone = ['all', 'ios', 'android'];
      } else this.QQ.phone = [];

      this.getMT();
      this.getTF();
      this.getJH();
    },
    selectAll3() {
      this.QQ.country = this.list3;

      this.getMT();
      this.getTF();
      this.getJH();
    },
    qinchu3() {
      this.QQ.country = [];
      this.checked3 = false;
    },
    selectAll5() {
      this.QQ2.mt = this.list5;

      this.getTF();
      this.getJH();
    },
    qinchu5() {
      this.QQ2.mt = [];
      this.checked5 = false;
    },
    selectAll6() {
      this.QQ2.tf = this.list6;
      this.getJH();
    },
    qinchu6() {
      this.QQ2.tf = [];
      this.checked6 = false;
    },
    selectAll7() {
      this.QQ2.jh = this.list7;
      this.getJH();
    },
    qinchu7() {
      this.QQ2.jh = [];
      this.checked7 = false;
    },
    selectAll9() {
      this.renyuan = [];
      if (this.checked9) {
        this.renyuan = this.listr;
      } else this.renyuan = [];
    },
    qinchu9() {
      this.renyuan = [];
      this.checked9 = false;
    },
    getTable() {
      //发起get请求
      this.$http
        .get('outsea_account/init_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.tableData = res.data.list;
            this.pageInfo.total = res.data.list.length;
            this.tableColumns = [];
            for (var pro in res.data.list[0]) {
              this.tableColumns.push(pro);
            }
            this.tableColumns1 = this.tableColumns.splice(0, 1);
            this.tableColumns2 = this.tableColumns;
            this.pictLoading = false;
            this.$refs.searchInput.searchTable();
          }
        })
        .catch(function (err) {
          console.log(err);
        });

      /* this.$axios({
                method:'get',
                url:'http://192.168.1.29:8085/basicdata/test/'
            }).then((response) =>{          //这里使用了ES6的语法
                console.log(response)       //请求成功返回的数据
            }).catch((error) =>
                console.log(error)       //请求失败返回的数据
            ) */
    },
    // 触发搜索按钮
    handleSearch() {
      this.fanhui = true;
      var a = this.date;
      var b = [];
      var c = [];
      var d = [];
      var g1 = [];
      var g2 = [];
      var g3 = [];
      var g4 = [];
      var g5 = [];
      var g6 = [];
      if (this.QQ.gameName == '') b = this.list1;
      else b = this.QQ.gameName;
      if (this.QQ.phone == '') c = ['all', 'ios', 'android'];
      else c = this.QQ.phone;
      if (this.QQ.country == '') {
        d = ['all'];
      } else d = this.QQ.country;

      if (this.checkList[1] == undefined) g1 = [];
      else g1 = [this.checkList[1]];
      if (this.checkList[2] == undefined) g2 = [];
      else g2 = [this.checkList[2]];
      if (this.checkList[3] == undefined) g3 = [];
      else g3 = [this.checkList[3]];
      if (this.checkList[4] == undefined) g4 = [];
      else g4 = [this.checkList[4]];
      if (this.checkList[5] == undefined) g5 = [];
      else g5 = [this.checkList[5]];
      if (this.checkList[6] == undefined) g6 = [];
      else g6 = [this.checkList[6]];

      this.pictLoading = true;
      //post请求获取搜索内容
      this.$axios
        .post(
          'outsea_account/info/',
          {
            account: this.QQ2.tf,
            project: b,
            platform: c,
            date_range: a,
            country: d,
            campaign_name: this.QQ2.jh,
            media: this.QQ2.mt,
            mk_user: this.renyuan,
            group_1: g1,
            group_2: g2,
            group_3: g3,
            group_4: g4,
            group_5: g5,
            group_6: g6
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.tableData = res.data.list;
          this.pageInfo.total = res.data.list.length;
          // this.pageInfo.size = res.data.list.length;
          this.tableColumns = [];
          for (var pro in res.data.list[0]) {
            this.tableColumns.push(pro);
          }
          this.tableColumns1 = this.tableColumns.splice(0, res.data.fix_columns);
          this.tableColumns2 = this.tableColumns;
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout();
            // el-table加ref="multipleTable"
          });
          this.pictLoading = false;
          this.$refs.searchInput.searchTable();
        })
        .catch((e) => {});
    },
    handleSizeChange(val) {
      this.pageInfo.size = val;
    },
        // 改变当前页
    handlePageChange(val) {
      this.pageInfo.current = val;
    },
    // 检索
    SearchInput(tableList){
      this.tableSearchList = tableList;
      this.pageInfo.total = tableList.length;
    },
    //导出csv文件
    download() {
      //datas：数据，fields：字段名
      try {
        const result = json2csv.parse(this.tableData);
        // 判断浏览器类型
        if (
          (navigator.userAgent.indexOf('compatible') > -1 && navigator.userAgent.indexOf('MSIE') > -1) ||
          navigator.userAgent.indexOf('Edge') > -1
        ) {
          //IE10或Edge浏览器
          var BOM = '\uFEFF';
          var csvData = new Blob([BOM + result], { type: 'text/csv' });
          navigator.msSaveBlob(csvData, `test.csv`);
        } else {
          //非IE浏览器
          var csvContent = 'data:text/csv;charset=utf-8,\uFEFF' + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement('a');
          link.href = encodeURI(csvContent);
          link.download = `test.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    selectXM(val) {
      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === this.list1) {
        this.checked1 = true;
      } else this.checked1 = false;
      if (this.QQ.gameName != '') {
        this.$axios
          .post(
            'outsea_account/country_data/',
            {
              project: this.QQ.gameName,
              platform: ['all', 'ios', 'android']
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
            this.list3.unshift('all');
          })
          .catch((e) => {});
      }
      if (this.QQ.gameName == '') {
        this.$axios
          .post(
            'outsea_account/country_data/',
            {
              project: this.list1,
              platform: ['all', 'ios', 'android']
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
            this.list3.unshift('all');
          })
          .catch((e) => {});
      }
      this.getMT();
      this.getTF();
      this.getJH();
    },
    selectPT(val) {
      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === ['all', 'ios', 'android']) {
        this.checked2 = true;
      } else this.checked2 = false;
      if (this.QQ.phone != '' && this.QQ.phone != '') {
        this.$axios
          .post(
            'outsea_account/country_data/',
            {
              project: this.QQ.gameName,
              platform: this.QQ.phone
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
            this.list3.unshift('all');
          })
          .catch((e) => {});
      }
      if (this.QQ.gameName == '' && this.QQ.phone != '') {
        this.$axios
          .post(
            'outsea_account/country_data/',
            {
              project: this.list1,
              platform: this.QQ.phone
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
            this.list3.unshift('all');
          })
          .catch((e) => {});
      }
      if (this.QQ.gameName != '' && this.QQ.phone == '') {
        this.$axios
          .post(
            'outsea_account/country_data/',
            {
              project: this.QQ.gameName,
              platform: ['all', 'ios', 'android']
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
            this.list3.unshift('all');
          })
          .catch((e) => {});
      }
      if (this.QQ.gameName == '' && this.QQ.phone == '') {
        this.$axios
          .post(
            'outsea_account/country_data/',
            {
              project: this.list1,
              platform: ['all', 'ios', 'android']
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
            this.list3.unshift('all');
          })
          .catch((e) => {});
      }

      this.getMT();
      this.getTF();
      this.getJH();
    },
    selectGZ(val) {
      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === this.list3) {
        this.checked3 = true;
      } else this.checked3 = false;

      this.getMT();
      this.getTF();
      this.getJH();
    },
    selectMT() {
      this.getTF();
      this.getJH();
    },
    selectTF() {
      this.getJH();
    },
    //获取游戏名
    getXM() {
      this.$http
        .get('outsea_account/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.list1 = res.data.project_data;
            this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    //获取所有国家信息
    getGZ() {
      //Vue.http.options.emulateJSON = true;

      this.$http
        .get('outsea_account/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.list1 = res.data.project_data;
            this.list1.unshift('all');
            this.$axios
              .post(
                'outsea_account/country_data/',
                {
                  project: this.list1,
                  platform: ['all', 'ios', 'android']
                },
                {
                  headers: {
                    Authorization: 'JWT ' + localStorage.getItem('jwt-token')
                  }
                }
              )
              .then((res) => {
                this.list3 = res.data.country_data;
                this.list3.unshift('all');
              })
              .catch((e) => {});
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getMT() {
      this.$axios
        .post(
          'outsea_account/media_data/',
          {
            project: this.QQ.gameName,
            platform: this.QQ.phone,
            country: this.QQ.country
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.list5 = res.data.media_data;
        })
        .catch((e) => {});
    },
    getTF() {
      this.$axios
        .post(
          'outsea_account/account_data/',
          {
            project: this.QQ.gameName,
            platform: this.QQ.phone,
            country: this.QQ.country,
            media: this.QQ2.mt
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.list6 = res.data.account_data;
        })
        .catch((e) => {});
    },
    getJH() {
      this.$axios
        .post(
          'outsea_account/campaign_data/',
          {
            project: this.QQ.gameName,
            platform: this.QQ.phone,
            country: this.QQ.country,
            media: this.QQ2.mt,
            account: this.QQ2.tf
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.list7 = res.data.campaign_data;
        })
        .catch((e) => {});
    },
  // 访问
  PageCount() {
    this.$http
      .post('index/count/', 
      {
        page_name:"ADMAX戶頁面"
      },
      {
        headers: {
          Authorization: 'JWT ' + localStorage.getItem('jwt-token')
        }
      })
      .then(function (res) {
        if (res.data.code == 200) {
          // this.$refs.childProject.project_list = res.data.project_data;
          // this.list1.unshift('all');
        }
      })
      .catch(function (err) {
        console.log('Auth Error');
        if (localStorage.getItem("jwt-token") != null){
          localStorage.clear();
          window.location.href="/#/login";
        } 
      });
  },
  // 获取之前的日期的函数
  getBeforeDate(n) {
          let _n = n;
          let _d = new Date();
          let _year = _d.getFullYear();
          let _mon = _d.getMonth() + 1;
          let _day = _d.getDate();
          if(_day <= _n) {
              if(_mon > 1) {
                  _mon = _mon - 1;
              } else {
                  _year = _year - 1;
                  _mon = 12;
              }
          }
          _d.setDate(_d.getDate() - n);
          _year = _d.getFullYear();
          _mon = _d.getMonth() + 1;
          _day = _d.getDate();
          var date_sub = _year + "-" + (_mon < 10 ? ('0' + _mon) : _mon) + "-" + (_day < 10 ? ('0' + _day) : _day);
          return date_sub;
      }
  },

  mounted() {
    let timeStamp = new Date();
    let year = new Date(timeStamp).getFullYear();
    let month = new Date(timeStamp).getMonth() + 1 < 10 ? '0' + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1;
    let month1 = new Date(timeStamp).getMonth() < 10 ? '0' + new Date(timeStamp).getMonth() : new Date(timeStamp).getMonth();
    let date = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate();
    let date_sub = this.getBeforeDate(30);
    this.date = [date_sub, year + '-' + month + '-' + date];
  },

  watch: {
    inputXM() {
      var newlist1 = [];
      this.list1.filter((item) => {
        if (item.toUpperCase().includes(this.inputXM.toUpperCase())) {
          newlist1.push(item);
        }
      });
      this.list1 = newlist1;
      if (this.inputXM == '') {
        this.getXM();
      }
    },
    inputGZ() {
      var newlist3 = [];
      this.list3.filter((item) => {
        if (item.toUpperCase().includes(this.inputGZ.toUpperCase())) {
          newlist3.push(item);
        }
      });
      this.list3 = newlist3;
      if (this.inputGZ == '') {
        this.getGZ();
      }
    },
    inputMT() {
      var newlist5 = [];
      this.list5.filter((item) => {
        if (item.toUpperCase().includes(this.inputMT.toUpperCase())) {
          newlist5.push(item);
        }
      });
      this.list5 = newlist5;
      if (this.inputMT == '') {
        this.getMT();
      }
    },
    inputTF() {
      var newlist6 = [];
      this.list6.filter((item) => {
        if (item.toUpperCase().includes(this.inputTF.toUpperCase())) {
          newlist6.push(item);
        }
      });
      this.list6 = newlist6;
      if (this.inputTF == '') {
        this.getTF();
      }
    },
    inputJH() {
      var newlist7 = [];
      this.list7.filter((item) => {
        if (item.toUpperCase().includes(this.inputJH.toUpperCase())) {
          newlist7.push(item);
        }
      });
      this.list7 = newlist7;
      if (this.inputJH == '') {
        this.getJH();
      }
    }
  }
};
</script>
<style>
.el-tag .el-icon-close {
  float: right;
  margin-top: 4px;
}
.el-tag {
  background: white;
  width: 100%;
}
.el-table .cell,
.el-table th > .cell {
  white-space: nowrap;
  width: fit-content;
}
.el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.el-tag.el-tag--info .el-tag__close {
  display: none;
}
.el-tag.el-tag--info {
  border: none;
  background: white;
}
.el-select__tags-text {
  margin-left: 0px;
}
.el-date-editor--daterange.el-input__inner {
  width: 240px;
}
.el-picker-panel {
  margin-left: 240px;
}
</style>
<style scoped>
.table {
  width: 100%;
  font-size: 14px;
}
</style>